import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getRegions } from '../../actions/getRegions.actions';
import { getDistricts } from '../../actions/getDistricts.actions';
import { getCategories } from '../../actions/getCategories.actions';
import Analytics from './analytics.component';

function AnalyticsContainer(props) {
  const {
    getRegions,
    getDistricts,
    getCategories,
    regionsData,
    districtsData,
    categoriesData,
  } = props;

  const [filters, setFilters] = useState({
    created_from: null,
    created_to: null,
    type: null,
  });

  useEffect(() => {
    getRegions();
    getDistricts();
    getCategories();
  }, [getRegions, getDistricts, getCategories]);

  const dateId = 'analytics-date';
  const regions = regionsData.items ? regionsData.items : [];
  const districts = districtsData.items ? districtsData.items : [];
  const categories = categoriesData.items ? categoriesData.items : [];
  const fieldsLoading = regionsData.isFetching
    || districtsData.isFetching
    || categoriesData.isFetching;
  const regionsOptions = [{ label: 'Все', value: null }, ...Object.keys(regions).map(
    (k) => ({ label: regions[k].name, value: regions[k].id }),
  )];
  const districtsOptions = [{ label: 'Все', value: null }, ...Object.keys(districts).map(
    (k) => ({ label: districts[k].name, value: districts[k].id }),
  )];
  const categoriesOptions = [{ label: 'Все', value: null }, ...Object.keys(categories).map(
    (k) => ({ label: categories[k].name, value: categories[k].id }),
  )];
  const typesOptions = [
    { label: 'Все типы', value: null },
    { label: 'Обращения', value: 'problem' },
    { label: 'Обжалования', value: 'appeal' },
  ];

  const handleDatesBlur = (startDate, endDate) => {
    let start = null;
    let end = null;
    const now = new Date();

    if (startDate) {
      start = new Date(moment(startDate).valueOf());
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start = Math.round(start / 1000);
    }

    if (endDate) {
      end = new Date(moment(endDate).valueOf());

      if (
        end.getFullYear() === now.getFullYear()
        && end.getMonth() === now.getMonth()
        && end.getDate() === now.getDate()
      ) {
        end = Math.round(now / 1000);
      } else {
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end = Math.round(end / 1000);
      }
    }

    setFilters({
      ...filters,
      created_from: start,
      created_to: end,
    });
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = 'https://zabiznes.org/panel';
    }
  };
  const changeGlobalType = (type) => {
    setFilters({
      ...filters,
      type,
    });
  };

  return (
    <Analytics
      filters={filters}
      regionsOptions={regionsOptions}
      districtsOptions={districtsOptions}
      categoriesOptions={categoriesOptions}
      executorsOptions={executorsOptions}
      fieldsLoading={fieldsLoading}
      dateId={dateId}
      handleDatesBlur={handleDatesBlur}
      handleBack={handleBack}
      typesOptions={typesOptions}
      handleTypeChange={changeGlobalType}
    />
  );
}

const executorsOptions = [
  {
    label: 'Все',
    value: null,
  },
  {
    label: 'Министерство внутренних дел РФ',
    value: '9041',
  },
  {
    label: 'Федеральная служба безопасности РФ',
    value: '9048',
  },
  {
    label: 'Следственный комитет РФ',
    value: '9049',
  },
  {
    label: 'Генеральная прокуратура РФ',
    value: '9050',
  },
];

const mapStateToProps = (state) => {
  const {
    filters: {
      categories: categoriesData,
      districts: districtsData,
      regions: regionsData,
    },
  } = state;

  return {
    regionsData,
    districtsData,
    categoriesData,
  };
};

export default connect(mapStateToProps, {
  getRegions,
  getDistricts,
  getCategories,
})(AnalyticsContainer);
