import { combineReducers } from 'redux';
import {
  doFetchListValuesForReportRequest,
  doFetchListValuesForReportSuccess,
  doFetchListValuesForReportFailure,
  getDistrictsDownloadSectionRequest,
  getDistrictsDownloadSectionSuccess,
  getDistrictsDownloadSectionFailure,
  getDistrictsByRegionDownloadSectionRequest,
  getDistrictsByRegionDownloadSectionSuccess,
  getDistrictsByRegionDownloadSectionFailure,
  getRegionsDownloadSectionRequest,
  getRegionsDownloadSectionSuccess,
  getRegionsDownloadSectionFailure,
} from '../actions/downloadStatistics.actions';

const analyticsLists = (state = {}, action) => {
  switch (action.type) {
  case doFetchListValuesForReportRequest:
    return {
      ...state,
    };
  case doFetchListValuesForReportSuccess:
    return {
      ...state,
      [action.payload.data.list]: action.payload.data.items,
    };
  case doFetchListValuesForReportFailure:
    return {};
  default:
    return state;
  }
};

const regionsList = (state = { items: [], isFetching: false }, action) => {
  switch (action.type) {
  case getRegionsDownloadSectionRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getRegionsDownloadSectionSuccess:
    return {
      items: action.payload.items,
      isFetching: false,
    };
  case getRegionsDownloadSectionFailure:
    return {
      items: [],
      isFetching: false,
    };
  default:
    return state;
  }
};

const districtList = (state = { items: [], isFetching: false }, action) => {
  switch (action.type) {
  case getDistrictsDownloadSectionRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getDistrictsDownloadSectionSuccess:
    return {
      items: action.payload.items,
      isFetching: false,
    };
  case getDistrictsDownloadSectionFailure:
    return {
      items: [],
      isFetching: false,
    };
  default:
    return state;
  }
};

const selectedDistrict = (state = { items: [], isFetching: false }, action) => {
  switch (action.type) {
  case getDistrictsByRegionDownloadSectionRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getDistrictsByRegionDownloadSectionSuccess:
    return {
      items: action.payload.items,
      isFetching: false,
    };
  case getDistrictsByRegionDownloadSectionFailure:
    return {
      items: [],
      isFetching: false,
    };
  default:
    return state;
  }
};

const downloadSection = combineReducers({
  analyticsLists,
  regionsList,
  districtList,
  selectedDistrict,
});

export default downloadSection;
