import React from 'react';
import styles from './infoCard.module.css';

function InfoCard(props) {
  const {
    title,
    number,
    icon,
    dataFetched,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>{title}</h3>
        {dataFetched && (
          <p className={styles.number}>{number}</p>
        )}
        {!dataFetched && (
          <p className={styles.number}>0</p>
        )}
      </div>
      <img className={styles.icon} src={icon} alt="logo" />
    </div>
  );
}

export default InfoCard;
