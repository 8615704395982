import {
  getStatisticIssuesEventTypeRequest,
  getStatisticIssuesEventTypeSuccess,
  getStatisticIssuesEventTypeFailure,
} from '../actions/issuesEventType.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesEventTypeStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesEventTypeRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesEventTypeSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesEventTypeFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesEventTypeStatistic;
