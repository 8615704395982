import React from 'react';
import { DateRangePicker as DateRangePickerRaw, isInclusivelyAfterDay } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import 'moment/locale/ru';

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null,
    };

    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleDatesChange({ startDate, endDate }) {
    let { focusedInput } = this.state;
    let newEndDate = endDate;

    if (focusedInput === END_DATE && endDate === null) {
      newEndDate = startDate;
    }

    if (focusedInput === START_DATE) {
      focusedInput = END_DATE;
      newEndDate = null;
    }

    this.setState({ focusedInput, startDate, endDate: newEndDate });

    if (this.props.onChange) {
      this.props.onChange(startDate, newEndDate);
    }
  }

  handleFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  handleBlur({ startDate, endDate }) {
    this.props.onBlur && this.props.onBlur(startDate, endDate);
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;
    const { id, className } = this.props;

    return (
      <div id={id} className={className}>
        <DateRangePickerRaw
          onClose={this.handleBlur}
          onDatesChange={this.handleDatesChange}
          onFocusChange={this.handleFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          startDatePlaceholderText="Начало"
          endDatePlaceholderText="Конец"
          minimumNights={0}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          startDateId={`${id}-start`}
          endDateId={`${id}-end`}
          isOutsideRange={(day) => isInclusivelyAfterDay(day, moment().add(1, 'd'))}
        />
      </div>
    );
  }
}

export default DateRangePicker;
