import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesApplicants } from '../../actions/issuesApplicants.actions';
import WhoIsApplicant from './whoIsApplicant.component';

function WhoIsApplicantContainer(props) {
  const { dateFilters, getStatisticIssuesApplicants, issuesApplicantsStatistic } = props;

  useEffect(() => {
    getStatisticIssuesApplicants(dateFilters);
  }, [dateFilters, getStatisticIssuesApplicants]);

  const colors = [
    '#ec412c', '#2196f3', '#4caf50', '#9c27b0', '#3f51b5', '#ff9800', '#00E396', '#008FFB', '#00EA7B',
  ];

  const applicants = issuesApplicantsStatistic.items
    ? issuesApplicantsStatistic.items
      .map((applicant, index) => (
        {
          title: applicant.name,
          value: applicant.statistic.total,
          color: colors[index],
        }
      ))
      .sort((a, b) => (a.value < b.value ? 1 : -1)) : [];

  const applicantsAmount = applicants.reduce((a, b) => a + (b.value || 0), 0);
  const dataLoading = !issuesApplicantsStatistic.items || issuesApplicantsStatistic.isFetching;

  return (
    <WhoIsApplicant
      applicants={applicants}
      applicantsAmount={applicantsAmount}
      dataLoading={dataLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    statistic: {
      issuesApplicantsStatistic,
    },
  } = state;

  return { issuesApplicantsStatistic };
};

export default connect(mapStateToProps, {
  getStatisticIssuesApplicants,
})(WhoIsApplicantContainer);
