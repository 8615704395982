import axios from '../utils/axios';

export const getDistrictsRequest = 'GET_DISTRICTS_REQUEST';
export const getDistrictsSuccess = 'GET_DISTRICTS_SUCCESS';
export const getDistrictsFailure = 'GET_DISTRICTS_FAILURE';

export const districtsRequest = () => ({
  type: getDistrictsRequest,
});

export const districtsSuccess = (items) => ({
  type: getDistrictsSuccess,
  payload: {
    items,
  },
});

export const districtsFailure = (error) => ({
  type: getDistrictsFailure,
  payload: {
    error,
  },
});

export const getDistricts = () => (dispatch) => {
  dispatch(districtsRequest());

  return axios('/api/location/district', {
    params: {
      perPage: -1,
    },
  })
    .then((response) => {
      dispatch(districtsSuccess(response.data.items));
    })
    .catch((err) => {
      dispatch(districtsFailure(err));
    });
};

export const getDistrictsStatusRequest = 'GET_DISTRICTS_STATUS_REQUEST';
export const getDistrictsStatusSuccess = 'GET_DISTRICTS_STATUS_SUCCESS';
export const getDistrictsStatusFailure = 'GET_DISTRICTS_STATUS_FAILURE';

export const districtsStatusRequest = () => ({
  type: getDistrictsStatusRequest,
});

export const districtsStatusSuccess = (items) => ({
  type: getDistrictsStatusSuccess,
  payload: {
    items,
  },
});

export const districtsStatusFailure = (error) => ({
  type: getDistrictsStatusFailure,
  payload: {
    error,
  },
});

export const getDistrictsStatus = (regionId) => (dispatch) => {
  dispatch(districtsStatusRequest());

  return axios(`/api/location/district/region/${regionId}`)
    .then((response) => {
      dispatch(districtsStatusSuccess(response.data));
    })
    .catch((err) => {
      dispatch(districtsStatusFailure(err));
    });
};

export const getDistrictsThemesRequest = 'GET_DISTRICTS_THEMES_REQUEST';
export const getDistrictsThemesSuccess = 'GET_DISTRICTS_THEMES_SUCCESS';
export const getDistrictsThemesFailure = 'GET_DISTRICTS_THEMES_FAILURE';

export const districtsThemesRequest = () => ({
  type: getDistrictsThemesRequest,
});

export const districtsThemesSuccess = (items) => ({
  type: getDistrictsThemesSuccess,
  payload: {
    items,
  },
});

export const districtsThemesFailure = (error) => ({
  type: getDistrictsThemesFailure,
  payload: {
    error,
  },
});

export const getDistrictsThemes = (regionId) => (dispatch) => {
  dispatch(districtsThemesRequest());

  return axios(`/api/location/district/region/${regionId}`)
    .then((response) => {
      dispatch(districtsThemesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(districtsThemesFailure(err));
    });
};

export const getDistrictsExecutorsRequest = 'GET_DISTRICTS_EXECUTORS_REQUEST';
export const getDistrictsExecutorsSuccess = 'GET_DISTRICTS_EXECUTORS_SUCCESS';
export const getDistrictsExecutorsFailure = 'GET_DISTRICTS_EXECUTORS_FAILURE';

export const districtsExecutorsRequest = () => ({
  type: getDistrictsExecutorsRequest,
});

export const districtsExecutorsSuccess = (items) => ({
  type: getDistrictsExecutorsSuccess,
  payload: {
    items,
  },
});

export const districtsExecutorsFailure = (error) => ({
  type: getDistrictsExecutorsFailure,
  payload: {
    error,
  },
});

export const getDistrictsExecutors = (regionId) => (dispatch) => {
  dispatch(districtsExecutorsRequest());

  return axios(`/api/location/district/region/${regionId}`)
    .then((response) => {
      dispatch(districtsExecutorsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(districtsExecutorsFailure(err));
    });
};
