import {
  getStatisticIssuesCoexecutorsRequest,
  getStatisticIssuesCoexecutorsSuccess,
  getStatisticIssuesCoexecutorsFailure,
} from '../actions/issuesCoexecutors.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesCoexecutorsStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesCoexecutorsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesCoexecutorsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesCoexecutorsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesCoexecutorsStatistic;
