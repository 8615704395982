import {
  getStatisticIssuesCriminalRequest,
  getStatisticIssuesCriminalSuccess,
  getStatisticIssuesCriminalFailure,
} from '../actions/issuesCriminal.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesCriminalStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesCriminalRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesCriminalSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesCriminalFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesCriminalStatistic;
