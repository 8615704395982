import axios from '../utils/axios';

export const getStatisticIssuesRegionsRequest = 'GET_STATICTIC_ISSUES_REGIONS_REQUEST';
export const getStatisticIssuesRegionsSuccess = 'GET_STATICTIC_ISSUES_REGIONS_SUCCESS';
export const getStatisticIssuesRegionsFailure = 'GET_STATICTIC_ISSUES_REGIONS_FAILURE';

export const statisticIssuesRegionsRequest = () => ({
  type: getStatisticIssuesRegionsRequest,
});

export const statisticIssuesRegionsSuccess = (items) => ({
  type: getStatisticIssuesRegionsSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesRegionsFailure = (error) => ({
  type: getStatisticIssuesRegionsFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesRegions = (filters) => (dispatch) => {
  dispatch(statisticIssuesRegionsRequest());

  return axios('/api/statistic/history/issues/regions', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesRegionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesRegionsFailure(err));
    });
};
