import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssueSpheres } from '../../actions/issuesSpheres.actions';
import PopularSpheres from './popularSpheres.component';

function PopularSpheresContainer(props) {
  const { dateFilters, getStatisticIssueSpheres, issuesSpheresData } = props;

  const issuesSpheres = issuesSpheresData.items ? issuesSpheresData.items.slice(0, 5) : [];
  const dataLoading = !issuesSpheres.length || issuesSpheres.isFetching;

  useEffect(() => {
    const filters = {
      order_by_statistic: 'total',
      order_direction: 'desc',
    };

    getStatisticIssueSpheres({ ...dateFilters, ...filters });
  }, [dateFilters, getStatisticIssueSpheres]);

  return (
    <PopularSpheres
      issuesSpheres={issuesSpheres}
      dataLoading={dataLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    statistic: {
      issuesSpheresStatistic: issuesSpheresData,
    },
  } = state;

  return { issuesSpheresData };
};

export default connect(mapStateToProps, {
  getStatisticIssueSpheres,
})(PopularSpheresContainer);
