import axios from '../utils/axios';

export const getStatisticIssuesRequest = 'GET_STATICTIC_ISSUES_REQUEST';
export const getStatisticIssuesSuccess = 'GET_STATICTIC_ISSUES_SUCCESS';
export const getStatisticIssuesFailure = 'GET_STATICTIC_ISSUES_FAILURE';

export const statisticIssuesRequest = () => ({
  type: getStatisticIssuesRequest,
});

export const statisticIssuesSuccess = (items) => ({
  type: getStatisticIssuesSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesFailure = (error) => ({
  type: getStatisticIssuesFailure,
  payload: {
    error,
  },
});

export const getStatisticIssues = (filters) => (dispatch) => {
  dispatch(statisticIssuesRequest());

  return axios('/api/statistic/issues', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesFailure(err));
    });
};

export const getStatisticIssuesByStatusRequest = 'GET_STATICTIC_ISSUES_BY_STATUS_REQUEST';
export const getStatisticIssuesByStatusSuccess = 'GET_STATICTIC_ISSUES_BY_STATUS_SUCCESS';
export const getStatisticIssuesByStatusFailure = 'GET_STATICTIC_ISSUES_BY_STATUS_FAILURE';

export const statisticIssuesByStatusRequest = () => ({
  type: getStatisticIssuesByStatusRequest,
});

export const statisticIssuesByStatusSuccess = (items) => ({
  type: getStatisticIssuesByStatusSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesByStatusFailure = (error) => ({
  type: getStatisticIssuesByStatusFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesByStatus = (filters) => (dispatch) => {
  dispatch(statisticIssuesByStatusRequest());

  return axios('/api/statistic/issues', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesByStatusSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesByStatusFailure(err));
    });
};
