import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesRegions } from '../../actions/issuesRegions.actions';
import { getStatisticUsersRegions } from '../../actions/userRegions.actions';
import MostIssuesRegions from './mostIssuesRegions.component';

function MostIssuesRegionsContainer(props) {
  const {
    dateFilters,
    getStatisticIssuesRegions,
    getStatisticUsersRegions,
    issuesRegionsStatistic,
    usersRegionsStatistic,
  } = props;

  useEffect(() => {
    getStatisticIssuesRegions(dateFilters);
    getStatisticUsersRegions(dateFilters);
  }, [dateFilters, getStatisticIssuesRegions, getStatisticUsersRegions]);

  const dataFetched = issuesRegionsStatistic.items && usersRegionsStatistic.items;
  const regionsStatisticData = dataFetched ? (
    issuesRegionsStatistic.items
      .map((issue) => (
        {
          ...issue,
          users: usersRegionsStatistic.items.find((user) => issue.id === user.id).statistic.total,
        }
      ))
      .sort((a, b) => ((a.statistic.total < b.statistic.total) ? 1 : -1))
      .slice(0, 5)
  ) : [];
  const dataLoading = !regionsStatisticData
    || (issuesRegionsStatistic.isFetching && usersRegionsStatistic.isFetching);

  return (
    <MostIssuesRegions
      regionsStatisticData={regionsStatisticData}
      dataLoading={dataLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    statistic: {
      issuesRegionsStatistic,
      usersRegionsStatistic,
    },
  } = state;

  return { issuesRegionsStatistic, usersRegionsStatistic };
};

export default connect(mapStateToProps, {
  getStatisticIssuesRegions,
  getStatisticUsersRegions,
})(MostIssuesRegionsContainer);
