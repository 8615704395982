import React from 'react';
import cn from 'classnames';
import styles from './issuesByStatus.module.css';
import Select from '../../ui-kit/select/select.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function IssuesByStatus(props) {
  const {
    categoriesOptions,
    districtsOptions,
    regionsOptions,
    issues,
    filters,
    setFilters,
    executorsOptions,
    dataLoading,
    handleDistrictChange,
    handleRegionChange,
    locations,
    regionsLoading,
    districtsLoading,
    fieldsLoading,
  } = props;

  return (
    <section
      className={cn(styles.container, {
        'loading-container': dataLoading,
      })}
    >
      <h2 className={styles.title}>
        Количество обращений по статусу
      </h2>
      <div className={styles.filters}>
        <Select
          placeholder="Федеральный округ"
          className={styles.filters__district}
          options={districtsOptions}
          disabled={fieldsLoading || districtsLoading}
          onChange={(district) => handleDistrictChange(district)}
          value={locations.district}
        />
        <Select
          placeholder="Регион"
          className={styles.filters__region}
          options={regionsOptions}
          disabled={fieldsLoading || regionsLoading}
          onChange={(region) => handleRegionChange(region)}
          value={locations.region}
        />
        <Select
          placeholder="Тема"
          className={styles.filters__theme}
          options={categoriesOptions}
          disabled={fieldsLoading}
          onChange={(category) => setFilters({ ...filters, category: category.value })}
        />
        <Select
          placeholder="Исполнители"
          className={styles.filters__executors}
          options={executorsOptions}
          onChange={(executor) => setFilters({ ...filters, executor: executor.value })}
        />
      </div>
      <div className={styles.tables}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td />
              <td className={styles.theadTd}>Статус</td>
              <td className={cn(styles.theadTd, styles.theadTdAmount)}>Кол-во обращений</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {issues
              .slice(0, Math.ceil(issues.length / 2))
              .map((issue, index) => (
                <tr className={styles.tr} key={issue.status + issue.amount}>
                  <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                  <td className={styles.td}>{issue.status}</td>
                  <td className={cn(styles.td, styles.tdAmount)}>{`${issue.amount}`}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td />
              <td className={styles.theadTd}>Статус</td>
              <td className={cn(styles.theadTd, styles.theadTdAmount)}>Кол-во обращений</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {issues
              .slice(Math.ceil(issues.length / 2), issues.length)
              .map((issue, index) => (
                <tr className={styles.tr} key={issue.status + issue.amount}>
                  <td className={cn(styles.td, styles.tdNumber)}>
                    {`${index + Math.ceil(issues.length / 2) + 1}.`}
                  </td>
                  <td className={styles.td}>{issue.status}</td>
                  <td className={cn(styles.td, styles.tdAmount)}>{`${issue.amount}`}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <CssLoader />
    </section>
  );
}

export default IssuesByStatus;
