import React, { useState } from 'react';
import cn from 'classnames';
import styles from './executorsRating.module.css';
import Select from '../../ui-kit/select/select.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function ExecutorsRating(props) {
  const {
    categoriesOptions,
    regionsOptions,
    districtsOptions,
    filters,
    setFilters,
    executors,
    coexecutors,
    executorsLoading,
    coexecutorsLoading,
    handleDistrictChange,
    handleRegionChange,
    locations,
    regionsLoading,
    districtsLoading,
    fieldsLoading,
  } = props;

  const [executorsRating, setExecutorsRating] = useState(true);

  return (
    <section
      className={cn(styles.container, {
        'loading-container': executorsLoading,
      })}
    >
      <div className={styles.menu}>
        <button
          type="button"
          className={cn(styles.button, { [styles.button_active]: executorsRating })}
          onClick={() => setExecutorsRating(!executorsRating)}
        >
          Рейтинг исполнителей
        </button>
        <button
          type="button"
          className={cn(styles.button, { [styles.button_active]: !executorsRating })}
          onClick={() => setExecutorsRating(!executorsRating)}
        >
          Рейтинг соисполнителей
        </button>
      </div>
      <div className={styles.filters}>
        <Select
          placeholder="Федеральный округ"
          className={styles.filter}
          options={districtsOptions}
          disabled={fieldsLoading || districtsLoading}
          onChange={(district) => handleDistrictChange(district)}
          value={locations.district}
        />
        <Select
          placeholder="Регион"
          className={styles.filter}
          options={regionsOptions}
          disabled={fieldsLoading || regionsLoading}
          onChange={(region) => handleRegionChange(region)}
          value={locations.region}
        />
        <Select
          placeholder="Тема"
          className={styles.filter_theme}
          options={categoriesOptions}
          disabled={fieldsLoading}
          onChange={(category) => setFilters({ ...filters, category: category.value })}
        />
      </div>
      {executorsRating && (
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td className={styles.tdNumber}>&nbsp;</td>
              <td className={cn(styles.theadTd, styles.theadTdExecutor)}>Исполнители</td>
              <td className={styles.theadTd}>Кол-во обращений</td>
              <td className={styles.theadTd}>Кол-во в работе</td>
              <td className={styles.theadTd}>Кол-во с ответом</td>
              <td className={styles.theadTd}>Кол-во решенных</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {executors.map((executor, index) => (
              <tr className={styles.tr} key={executor.id}>
                <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                <td className={cn(styles.td, styles.tdExecutor)}>
                  {/* <img className={styles.icon} src={executor.icon} alt="logo" /> */}
                  {executor.name}
                </td>
                <td className={styles.td}>{executor.statistic.total}</td>
                <td className={styles.td}>{executor.statistic.in_work}</td>
                <td className={styles.td}>{executor.statistic.WithAnswerPrivate}</td>
                <td className={styles.td}>{executor.statistic.ResolvedPrivate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!executorsRating && (
        <div className={cn({ 'loading-container': coexecutorsLoading })}>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <td className={styles.tdNumber}>&nbsp;</td>
                <td className={cn(styles.theadTd, styles.theadTdExecutor)}>Соисполнители</td>
                <td className={styles.theadTd}>Кол-во обращений</td>
                <td className={styles.theadTd}>Кол-во в работе</td>
                <td className={styles.theadTd}>Кол-во с ответом</td>
                <td className={styles.theadTd}>Кол-во решенных</td>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {coexecutors.map((executor, index) => (
                <tr className={styles.tr} key={executor.id}>
                  <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                  <td className={cn(styles.td, styles.tdExecutor)}>
                    {/* <img className={styles.icon} src={executor.icon} alt="logo" /> */}
                    {executor.name}
                  </td>
                  <td className={styles.td}>{executor.statistic.total}</td>
                  <td className={styles.td}>{executor.statistic.in_work}</td>
                  <td className={styles.td}>{executor.statistic.WithAnswerPrivate}</td>
                  <td className={styles.td}>{executor.statistic.ResolvedPrivate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <CssLoader />
        </div>
      )}
      <CssLoader />
    </section>
  );
}

export default ExecutorsRating;
