import {
  getStatisticIssueSpheresRequest,
  getStatisticIssueSpheresSuccess,
  getStatisticIssueSpheresFailure,
} from '../actions/issuesSpheres.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesSpheresStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssueSpheresRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssueSpheresSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssueSpheresFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesSpheresStatistic;
