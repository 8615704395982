import axios from '../utils/axios';

export const getStatisticUsersRequest = 'GET_STATICTIC_USERS_REQUEST';
export const getStatisticUsersSuccess = 'GET_STATICTIC_USERS_SUCCESS';
export const getStatisticUsersFailure = 'GET_STATICTIC_USERS_FAILURE';

export const statisticUsersRequest = () => ({
  type: getStatisticUsersRequest,
});

export const statisticUsersSuccess = (items) => ({
  type: getStatisticUsersSuccess,
  payload: {
    items,
  },
});

export const statisticUsersFailure = (error) => ({
  type: getStatisticUsersFailure,
  payload: {
    error,
  },
});

export const getStatisticUsers = (filters) => (dispatch) => {
  dispatch(statisticUsersRequest());

  return axios('/api/statistic/users', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticUsersFailure(err));
    });
};
