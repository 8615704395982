import {
  getStatisticIssuesApplicantsRequest,
  getStatisticIssuesApplicantsSuccess,
  getStatisticIssuesApplicantsFailure,
} from '../actions/issuesApplicants.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesApplicantsStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesApplicantsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesApplicantsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesApplicantsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesApplicantsStatistic;
