import React from 'react';
import Region from '../region/region.component';
import styles from './map.module.css';

function Map(props) {
  const {
    color,
    indicator,
    regionsData,
    currentRegion,
    clickCoordinates,
    handleRegionClick,
  } = props;

  return (
    <div>
      <svg width="750" height="450" viewBox="0 0 724 411" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          {regionsData.map((region) => (
            <Region
              coordinates={region.coordinates}
              color={color}
              opacity={region.opacity}
              stroke="white"
              key={region.id}
              onClick={(e) => handleRegionClick(e, region)}
            />
          ))}
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="724" height="411" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {currentRegion && (
        <div
          style={{
            display: clickCoordinates.show ? 'block' : 'none',
            left: clickCoordinates.x,
            top: clickCoordinates.y,
          }}
          className={styles.regionInfo}
        >
          <p className={styles.regionalInfo__title}>
            {currentRegion.name}
          </p>
          <p className={styles.regionalInfo__data}>
            {currentRegion.statistic[`${indicator}`]}
          </p>
        </div>
      )}
    </div>
  );
}

export default Map;
