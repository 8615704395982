import {
  getStatisticUsersRegionsRequest,
  getStatisticUsersRegionsSuccess,
  getStatisticUsersRegionsFailure,
} from '../actions/userRegions.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const usersRegionsStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticUsersRegionsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticUsersRegionsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticUsersRegionsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default usersRegionsStatistic;
