import React from 'react';
import cn from 'classnames';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import styles from './whoIsApplicant.module.css';
import LegendItem from '../../ui-kit/legendItem/legendItem.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function WhoIsApplicant(props) {
  const {
    applicants,
    applicantsAmount,
    dataLoading,
  } = props;

  return (
    <section
      className={cn(styles.container, {
        'loading-container': dataLoading,
      })}
    >
      <h2 className={styles.title}>
        Кем является заявитель
      </h2>
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          <ReactMinimalPieChart
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={applicants}
            label={false}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={40}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={2}
            radius={50}
            rounded={false}
            startAngle={0}
            viewBoxSize={[
              100,
              100,
            ]}
          />
        </div>
        <ul className={styles.legend}>
          {applicants.map((applicant) => (
            <li key={applicant.color} className={styles.legend__item}>
              <LegendItem
                color={applicant.color}
                title={applicant.title}
                data={`${applicant.value} (${(((applicant.value / applicantsAmount) || 0) * 100).toFixed(1)}%)`}
              />
            </li>
          ))}
        </ul>
      </div>
      <CssLoader />
    </section>
  );
}

export default WhoIsApplicant;
