import React from 'react';
import cn from 'classnames';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import Chart from 'react-apexcharts';
import styles from './issueDeadlines.module.css';
import Select from '../../ui-kit/select/select.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';
import LegendItem from '../../ui-kit/legendItem/legendItem.component';

function IssueDeadlines(props) {
  const {
    filters,
    setFilters,
    inProgress,
    answeredIssuesAmount,
    waitingIssuesAmount,
    responceDays,
    graphData,
    executorsOptions,
    barChartOption,
    answeredIssues,
    waitingIssues,
    dataLoading,
    executor,
  } = props;

  return (
    <section
      className={cn(styles.container, {
        'loading-container': dataLoading,
      })}
    >
      <h2 className={styles.title}>
        Сроки исполнения обращения
      </h2>
      <label className={styles.filterLabel}>
        <p className={styles.filterText}>
          Выберите фильтр по исполнителю
        </p>
        <Select
          placeholder="Исполнитель"
          className={styles.filter}
          options={executorsOptions}
          value={executor}
          onChange={(executor) => setFilters({ ...filters, executor: executor.value })}
        />
      </label>
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          <ReactMinimalPieChart
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={[...answeredIssues, ...waitingIssues]}
            label={false}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={40}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={2}
            radius={50}
            rounded={false}
            startAngle={0}
            viewBoxSize={[
              100,
              100,
            ]}
          />
        </div>
        <div>
          <h3 className={styles.chartTitle}>{`Взято в работу: ${inProgress}`}</h3>
          <div className={styles.tables}>
            <table className={styles.table}>
              <thead className={styles.thead}>
                <tr>
                  <td className={styles.theadTd}>С ответом:</td>
                  <td className={styles.theadTd}>
                    {answeredIssuesAmount && (
                      `${answeredIssuesAmount} (${((answeredIssuesAmount / inProgress) * 100).toFixed()}%)`
                    )}
                  </td>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {answeredIssues.map((issue) => (
                  <tr className={styles.tr} key={issue.color}>
                    <td className={styles.td}>
                      <LegendItem
                        color={issue.color}
                        title={issue.title}
                      />
                    </td>
                    <td className={styles.td}>{issue.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className={styles.table}>
              <thead className={styles.thead}>
                <tr>
                  <td className={styles.theadTd}>Ожидают ответа:</td>
                  <td className={styles.theadTd}>
                    {waitingIssuesAmount && (
                      `${waitingIssuesAmount} (${((waitingIssuesAmount / inProgress) * 100).toFixed()}%)`
                    )}
                  </td>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {waitingIssues.map((issue) => (
                  <tr className={styles.tr} key={issue.color}>
                    <td className={styles.td}>
                      <LegendItem
                        color={issue.color}
                        title={issue.title}
                      />
                    </td>
                    <td className={styles.td}>{issue.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.graphContainer}>
            <ul className={styles.graphLegendList}>
              {responceDays.map((time) => (
                <li className={styles.graphLegendItem} key={time.value + time.title}>
                  <span className={styles.graphNumber}>{`${time.value} дней `}</span>
                  {`– ${time.title}`}
                </li>
              ))}
            </ul>
            <Chart
              options={barChartOption.options}
              series={graphData}
              type="bar"
              width="400"
              height="220"
            />
          </div>
        </div>
      </div>
      <CssLoader />
    </section>
  );
}

export default IssueDeadlines;
