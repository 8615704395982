import {
  getRegionsRequest,
  getRegionsSuccess,
  getRegionsFailure,
  getRegionsStatusRequest,
  getRegionsStatusSuccess,
  getRegionsStatusFailure,
  getRegionsThemesRequest,
  getRegionsThemesSuccess,
  getRegionsThemesFailure,
  getRegionsExecutorsRequest,
  getRegionsExecutorsSuccess,
  getRegionsExecutorsFailure,
} from '../actions/getRegions.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

export const regions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getRegionsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getRegionsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getRegionsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const regionsStatus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getRegionsStatusRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getRegionsStatusSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getRegionsStatusFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const regionsThemes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getRegionsThemesRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getRegionsThemesSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getRegionsThemesFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const regionsExecutors = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getRegionsExecutorsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getRegionsExecutorsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getRegionsExecutorsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};
