import React from 'react';
import ReactSelect from 'react-select';
import './select.css';

function Select(props) {
  return (
    <div className={props.className}>
      <ReactSelect
        className="select"
        classNamePrefix="select"
        type={props.type}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        onFocus={props.onFocus}
        options={props.options}
        onBlur={props.onBlur}
        getOptionValue={props.getOptionValue}
        getOptionLabel={props.getOptionLabel}
        value={props.value}
        placeholder={props.placeholder}
        errorText={props.errorText}
        error={props.error}
        defaultValue={props.defaultValue}
      />
    </div>
  );
}

export default Select;
