import React from 'react';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import cn from 'classnames';
import styles from './generalIndicators.module.css';
import LegendItem from '../../ui-kit/legendItem/legendItem.component';
import InfoCard from '../infoCard/infoCard.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';
import Map from '../map/map.container';

function GeneralIndicators(props) {
  const {
    issuesTotal,
    issuesWithAnswer,
    issuesResolved,
    infoCards,
    indicators,
    allIssues,
    indicator,
    setIndicator,
    dataFetched,
    issuesRegions,
    currentRegion,
    setCurrentRegion,
  } = props;

  return (
    <section>
      <div
        className={cn({
          'loading-container': !dataFetched,
        })}
      >
        <ul className={styles.infoCards}>
          {infoCards.map((card) => (
            <li className={styles.infoCards_item} key={card.title + card.number}>
              <InfoCard
                title={card.title}
                number={card.number}
                icon={card.icon}
                dataFetched={dataFetched}
              />
            </li>
          ))}
        </ul>
        <CssLoader />
      </div>
      <div
        className={cn(styles.container, {
          'loading-container': !dataFetched,
        })}
      >
        <h2 className={styles.title}>
          Общие показатели
        </h2>
        <div className={styles.indicators}>
          <button
            type="button"
            className={
              cn(styles.indicatorsItem, { [styles.indicatorsItem_active]: indicator === 'total' })
            }
            onClick={() => setIndicator('total')}
          >
            <h3 className={styles.text}>Всего обращений</h3>
            {dataFetched && (
              <p className={styles.number}>{issuesTotal}</p>
            )}
          </button>
          <button
            type="button"
            className={
              cn(styles.indicatorsItem, { [styles.indicatorsItem_active]: indicator === 'answered' })
            }
            onClick={() => setIndicator('answered')}
          >
            <h3 className={styles.text}>Обращений с ответом</h3>
            {dataFetched && (
              <p className={styles.number}>{issuesWithAnswer}</p>
            )}
          </button>
          <button
            type="button"
            className={
              cn(styles.indicatorsItem, { [styles.indicatorsItem_active]: indicator === 'solved' })
            }
            onClick={() => setIndicator('solved')}
          >
            <h3 className={styles.text}>Решенных проблем</h3>
            {dataFetched && (
              <p className={styles.number}>{issuesResolved}</p>
            )}
          </button>
        </div>
        <div className={styles.mapContainer}>
          <Map
            indicator={indicator}
            issuesRegions={issuesRegions}
            currentRegion={currentRegion}
            setCurrentRegion={setCurrentRegion}
          />
          <div className={styles.chartContainer}>
            <div className={styles.chart}>
              <ReactMinimalPieChart
                animate={true}
                animationDuration={500}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={
                  indicators.reduce((a, b) => a + b.value, 0) !== 0
                    ? indicators
                    : [{
                      title: '',
                      value: 1,
                      color: 'rgba(90, 112, 129, 0.166)',
                    }]
                }
                label={false}
                labelPosition={50}
                lengthAngle={360}
                lineWidth={40}
                onClick={undefined}
                onMouseOut={undefined}
                onMouseOver={undefined}
                paddingAngle={2}
                radius={50}
                rounded={false}
                startAngle={0}
                viewBoxSize={[
                  100,
                  100,
                ]}
              />
            </div>
            <h4>Реакция пользователей</h4>
            <br />
            <ul className={styles.legend}>
              {indicators.map((indicatorItem) => (
                <li className={styles.legend__item} key={indicatorItem.color}>
                  <LegendItem
                    color={indicatorItem.color}
                    title={indicatorItem.title}
                    data={allIssues ? `${indicatorItem.value} (${((indicatorItem.value / allIssues) * 100).toFixed(1)}%)` : '0%'}
                  />
                </li>
              ))}
              <li className={styles.legend__item}>
                <LegendItem
                  title="Всего поступило ответов"
                  data={`${allIssues} (100%)`}
                />
              </li>
            </ul>
          </div>
        </div>
        <CssLoader />
      </div>
    </section>
  );
}

export default GeneralIndicators;
