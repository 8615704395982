import axios from '../utils/axios';

export const getStatisticIssuesExecutorsRequest = 'GET_STATICTIC_ISSUES_EXECUTORS_REQUEST';
export const getStatisticIssuesExecutorsSuccess = 'GET_STATICTIC_ISSUES_EXECUTORS_SUCCESS';
export const getStatisticIssuesExecutorsFailure = 'GET_STATICTIC_ISSUES_EXECUTORS_FAILURE';

export const statisticIssuesExecutorsRequest = () => ({
  type: getStatisticIssuesExecutorsRequest,
});

export const statisticIssuesExecutorsSuccess = (items) => ({
  type: getStatisticIssuesExecutorsSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesExecutorsFailure = (error) => ({
  type: getStatisticIssuesExecutorsFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesExecutors = (filters) => (dispatch) => {
  dispatch(statisticIssuesExecutorsRequest());

  return axios('/api/statistic/issues/executors', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesExecutorsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesExecutorsFailure(err));
    });
};
