import axios from '../utils/axios';

export const getStatisticIssuesCoexecutorsRequest = 'GET_STATICTIC_ISSUES_COEXECUTORS_REQUEST';
export const getStatisticIssuesCoexecutorsSuccess = 'GET_STATICTIC_ISSUES_COEXECUTORS_SUCCESS';
export const getStatisticIssuesCoexecutorsFailure = 'GET_STATICTIC_ISSUES_COEXECUTORS_FAILURE';

export const statisticIssuesCoexecutorsRequest = () => ({
  type: getStatisticIssuesCoexecutorsRequest,
});

export const statisticIssuesCoexecutorsSuccess = (items) => ({
  type: getStatisticIssuesCoexecutorsSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesCoexecutorsFailure = (error) => ({
  type: getStatisticIssuesCoexecutorsFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesCoexecutors = (filters) => (dispatch) => {
  dispatch(statisticIssuesCoexecutorsRequest());

  return axios('/api/statistic/issues/coexecutor', {
    params: filters,
  })
    .then((responsCoe) => {
      dispatch(statisticIssuesCoexecutorsSuccess(responsCoe.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesCoexecutorsFailure(err));
    });
};
