import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesCriminal } from '../../actions/issuesCriminal.actions';
import { getStatisticIssuesEventType } from '../../actions/issuesEventType.actions';

import IssuesAmount from './issuesAmount.component';

function IssuesAmountContainer(props) {
  const {
    dateFilters,
    getStatisticIssuesCriminal,
    getStatisticIssuesEventType,
    issuesCriminalStatistic,
    issuesEventTypeStatistic,
  } = props;

  const criminalIssues = issuesCriminalStatistic ? issuesCriminalStatistic.items : [];
  const withCriminal = criminalIssues
    ? criminalIssues.find((issues) => issues.id === 'with_criminal').statistic.total
    : 0;
  const notCriminal = criminalIssues
    ? criminalIssues.find((issues) => issues.id === 'not_criminal').statistic.total
    : 0;
  const criminalCases = criminalIssues ? [
    {
      title: 'Уголовное дело возбуждено',
      value: withCriminal,
      color: '#ec412c',
    },
    {
      title: 'Уголовное дело не возбуждено',
      value: notCriminal,
      color: '#2196f3',
    },
  ] : [];
  const criminalCasesAmount = criminalCases.reduce((a, b) => a + (b.value || 0), 0);
  const criminalIssuesLoading = !criminalIssues || issuesCriminalStatistic.isFetching;

  const colors = ['#ec412c', '#2196f3', '#4caf50', '#9c27b0', '#3f51b5', '#ff9800'];
  const eventType = issuesEventTypeStatistic.items ? issuesEventTypeStatistic.items : null;
  const events = eventType
    ? eventType
      .filter((eventType) => eventType.Название !== 'Всего')
      .map((eventType, index) => (
        {
          title: eventType.Название,
          value: eventType.Всего,
          color: colors[index],
        }
      )) : [];
  const eventsAmount = eventType ? eventType.find((event) => event.Название === 'Всего').Всего : 0;
  const eventTypeLoading = !eventType || issuesEventTypeStatistic.isFetching;

  useEffect(() => {
    getStatisticIssuesCriminal(dateFilters);
    getStatisticIssuesEventType(dateFilters);
  }, [dateFilters, getStatisticIssuesCriminal, getStatisticIssuesEventType]);

  return (
    <IssuesAmount
      criminalCases={criminalCases}
      criminalCasesAmount={criminalCasesAmount}
      events={events}
      eventsAmount={eventsAmount}
      eventTypeLoading={eventTypeLoading}
      criminalIssuesLoading={criminalIssuesLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    statistic: {
      issuesCriminalStatistic,
      issuesEventTypeStatistic,
    },
  } = state;

  return {
    issuesCriminalStatistic,
    issuesEventTypeStatistic,
  };
};

export default connect(mapStateToProps, {
  getStatisticIssuesCriminal,
  getStatisticIssuesEventType,
})(IssuesAmountContainer);
