import axios from '../utils/axios';

export const getStatisticUsersRegionsRequest = 'GET_STATICTIC_USERS_REGIONS_REQUEST';
export const getStatisticUsersRegionsSuccess = 'GET_STATICTIC_USERS_REGIONS_SUCCESS';
export const getStatisticUsersRegionsFailure = 'GET_STATICTIC_USERS_REGIONS_FAILURE';

export const statisticUsersRegionsRequest = () => ({
  type: getStatisticUsersRegionsRequest,
});

export const statisticUsersRegionsSuccess = (items) => ({
  type: getStatisticUsersRegionsSuccess,
  payload: {
    items,
  },
});

export const statisticUsersRegionsFailure = (error) => ({
  type: getStatisticUsersRegionsFailure,
  payload: {
    error,
  },
});

export const getStatisticUsersRegions = (filters) => (dispatch) => {
  dispatch(statisticUsersRegionsRequest());

  return axios('/api/statistic/users/regions', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticUsersRegionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticUsersRegionsFailure(err));
    });
};
