import axios from '../utils/axios';

export const getStatisticIssueSpheresRequest = 'GET_STATICTIC_ISSUE_SPHERES_REQUEST';
export const getStatisticIssueSpheresSuccess = 'GET_STATICTIC_ISSUE_SPHERES_SUCCESS';
export const getStatisticIssueSpheresFailure = 'GET_STATICTIC_ISSUE_SPHERES_FAILURE';

export const statisticIssueSpheresRequest = () => ({
  type: getStatisticIssueSpheresRequest,
});

export const statisticIssueSpheresSuccess = (items) => ({
  type: getStatisticIssueSpheresSuccess,
  payload: {
    items,
  },
});

export const statisticIssueSpheresFailure = (error) => ({
  type: getStatisticIssueSpheresFailure,
  payload: {
    error,
  },
});

export const getStatisticIssueSpheres = (filters) => (dispatch) => {
  dispatch(statisticIssueSpheresRequest());

  return axios('/api/statistic/issues/okved', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssueSpheresSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssueSpheresFailure(err));
    });
};
