import axios from '../utils/axios';

export const getRegionsRequest = 'GET_REGIONS_REQUEST';
export const getRegionsSuccess = 'GET_REGIONS_SUCCESS';
export const getRegionsFailure = 'GET_REGIONS_FAILURE';

export const regionsRequest = () => ({
  type: getRegionsRequest,
});

export const regionsSuccess = (items) => ({
  type: getRegionsSuccess,
  payload: {
    items,
  },
});

export const regionsFailure = (error) => ({
  type: getRegionsFailure,
  payload: {
    error,
  },
});

export const getRegions = () => (dispatch) => {
  dispatch(regionsRequest());

  return axios('/api/location/region', {
    params: {
      perPage: -1,
    },
  })
    .then((response) => {
      dispatch(regionsSuccess(response.data.items));
    })
    .catch((err) => {
      dispatch(regionsFailure(err));
    });
};

export const getRegionsStatusRequest = 'GET_REGIONS_STATUS_REQUEST';
export const getRegionsStatusSuccess = 'GET_REGIONS_STATUS_SUCCESS';
export const getRegionsStatusFailure = 'GET_REGIONS_STATUS_FAILURE';

export const regionsStatusRequest = () => ({
  type: getRegionsStatusRequest,
});

export const regionsStatusSuccess = (items) => ({
  type: getRegionsStatusSuccess,
  payload: {
    items,
  },
});

export const regionsStatusFailure = (error) => ({
  type: getRegionsStatusFailure,
  payload: {
    error,
  },
});

export const getRegionsStatus = (districtId) => (dispatch) => {
  dispatch(regionsStatusRequest());

  return axios(`/api/location/regions/district/${districtId}`)
    .then((response) => {
      dispatch(regionsStatusSuccess(response.data.items));
    })
    .catch((err) => {
      dispatch(regionsStatusFailure(err));
    });
};

export const getRegionsThemesRequest = 'GET_REGIONS_THEMES_REQUEST';
export const getRegionsThemesSuccess = 'GET_REGIONS_THEMES_SUCCESS';
export const getRegionsThemesFailure = 'GET_REGIONS_THEMES_FAILURE';

export const regionsThemesRequest = () => ({
  type: getRegionsThemesRequest,
});

export const regionsThemesSuccess = (items) => ({
  type: getRegionsThemesSuccess,
  payload: {
    items,
  },
});

export const regionsThemesFailure = (error) => ({
  type: getRegionsThemesFailure,
  payload: {
    error,
  },
});

export const getRegionsThemes = (districtId) => (dispatch) => {
  dispatch(regionsThemesRequest());

  return axios(`/api/location/regions/district/${districtId}`)
    .then((response) => {
      dispatch(regionsThemesSuccess(response.data.items));
    })
    .catch((err) => {
      dispatch(regionsThemesFailure(err));
    });
};

export const getRegionsExecutorsRequest = 'GET_REGIONS_EXECUTORS_REQUEST';
export const getRegionsExecutorsSuccess = 'GET_REGIONS_EXECUTORS_SUCCESS';
export const getRegionsExecutorsFailure = 'GET_REGIONS_EXECUTORS_FAILURE';

export const regionsExecutorsRequest = () => ({
  type: getRegionsExecutorsRequest,
});

export const regionsExecutorsSuccess = (items) => ({
  type: getRegionsExecutorsSuccess,
  payload: {
    items,
  },
});

export const regionsExecutorsFailure = (error) => ({
  type: getRegionsExecutorsFailure,
  payload: {
    error,
  },
});

export const getRegionsExecutors = (districtId) => (dispatch) => {
  dispatch(regionsExecutorsRequest());

  return axios(`/api/location/regions/district/${districtId}`)
    .then((response) => {
      dispatch(regionsExecutorsSuccess(response.data.items));
    })
    .catch((err) => {
      dispatch(regionsExecutorsFailure(err));
    });
};
