import React from 'react';
import cn from 'classnames';
import styles from './popularSpheres.module.css';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function PopularSpheres(props) {
  const { issuesSpheres, dataLoading } = props;

  return (
    <section
      className={cn(styles.container, {
        'loading-container': dataLoading,
      })}
    >
      <h2 className={styles.title}>
        Популярные сферы деятельности обратившихся предпринимателей
      </h2>
      <div className={styles.tables}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td className={styles.tdNumber} />
              <td className={cn(styles.theadTd, styles.theadTdSphere)}>Сфера деятельности</td>
              <td className={styles.theadTd}>Кол-во обращений</td>
              <td className={styles.theadTd}>Кол-во с ответом</td>
              <td className={styles.theadTd}>Кол-во решенных</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {issuesSpheres.map((issue, index) => (
              <tr className={styles.tr} key={issue.id}>
                <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                <td className={cn(styles.td, styles.tdSphere)}>{issue.name}</td>
                <td className={styles.td}>{issue.statistic.total}</td>
                <td className={styles.td}>{issue.statistic.WithAnswerPrivate}</td>
                <td className={styles.td}>{issue.statistic.ResolvedPrivate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CssLoader />
    </section>
  );
}

export default PopularSpheres;
