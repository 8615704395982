import axios from '../utils/axios';

export const getStatisticIssuesEventTypeRequest = 'GET_STATICTIC_ISSUES_EVENT_TYPE_REQUEST';
export const getStatisticIssuesEventTypeSuccess = 'GET_STATICTIC_ISSUES_EVENT_TYPE_SUCCIssuesESS';
export const getStatisticIssuesEventTypeFailure = 'GET_STATICTIC_ISSUES_EVENT_TYPE_FAILURE';

export const statisticIssuesEventTypeRequest = () => ({
  type: getStatisticIssuesEventTypeRequest,
});

export const statisticIssuesEventTypeSuccess = (items) => ({
  type: getStatisticIssuesEventTypeSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesEventTypeFailure = (error) => ({
  type: getStatisticIssuesEventTypeFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesEventType = (filters) => (dispatch) => {
  dispatch(statisticIssuesEventTypeRequest());

  return axios('/api/statistic/general/generate', {
    params: {
      row: 'action-type',
      ...filters,
    },
  })
    .then((response) => {
      dispatch(statisticIssuesEventTypeSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesEventTypeFailure(err));
    });
};
