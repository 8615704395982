import React from 'react';
import './cssLoader.css';

const CssLoader = () => (
  <div className="css-loader">
    <div className="dual-ring" />
  </div>
);

export default CssLoader;
