import {
  getStatisticUsersRequest,
  getStatisticUsersSuccess,
  getStatisticUsersFailure,
} from '../actions/users.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const usersStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticUsersRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticUsersSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticUsersFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default usersStatistic;
