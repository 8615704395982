/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesByStatus } from '../../actions/issues.actions';
import { getRegionsStatus } from '../../actions/getRegions.actions';
import { getDistrictsStatus } from '../../actions/getDistricts.actions';
import IssuesByStatus from './issuesByStatus.component';

function IssuesByStatusContainer(props) {
  const {
    dateFilters,
    executorsOptions,
    districtsOptions,
    regionsOptions,
    categoriesOptions,
    issuesStatisticData,
    getStatisticIssuesByStatus,
    getRegionsStatus,
    getDistrictsStatus,
    regionsData,
    districtsData,
    fieldsLoading,
  } = props;

  const [filters, setFilters] = useState({
    district: null,
    region: null,
    category: null,
    executor: null,
  });
  const [locations, setLocations] = useState({
    district: null,
    region: null,
  });
  const [regions, setRegions] = useState(null);

  useEffect(() => {
    if (!districtsData.isFetching && !regionsData.isFetching) {
      getStatisticIssuesByStatus({ ...dateFilters, ...filters });
    }
  }, [dateFilters, filters, getStatisticIssuesByStatus]);

  useEffect(() => {
    if (districtsData.items && !districtsData.isFetching) {
      setLocations({
        ...locations,
        district: { label: districtsData.items.name, value: districtsData.items.id },
      });
      setFilters({ ...filters, district: districtsData.items.id });
      getRegionsStatus(districtsData.items.id);
    }
  }, [districtsData]);

  useEffect(() => {
    if (regionsData.items && !regionsData.isFetching) {
      setRegions(regionsData.items);
      setFilters({ ...filters, region: locations.region ? locations.region.value : null });
    }
  }, [regionsData]);

  const handleDistrictChange = (district) => {
    if (district.value) {
      getRegionsStatus(district.value);
    }
    setLocations({ region: null, district });
    setFilters({ ...filters, district: district.value, region: null });
  };

  const handleRegionChange = (region) => {
    if (region.value) {
      getDistrictsStatus(region.value);
    }
    setLocations({ ...locations, region });
    setFilters({ ...filters, region: region.value });
  };

  const regionsOptionsData = regions && locations.district.value
    ? [
      { label: 'Все', value: null },
      ...Object.keys(regions).map(
        (k) => ({ label: regions[k].name, value: regions[k].id }),
      ),
    ]
    : regionsOptions;
  const issuesStatistic = issuesStatisticData.items ? issuesStatisticData.items : null;
  const issues = issuesStatistic ? [
    {
      status: 'C ответом',
      amount: issuesStatistic.WithAnswerPrivate,
    },
    {
      status: 'На рассмотрении',
      amount: issuesStatistic.InQuestionPrivate,
    },
    {
      status: 'Проведена консультация',
      amount: issuesStatistic.ConsultationHeldPrivate,
    },
    {
      status: 'Проблема решена',
      amount: issuesStatistic.ResolvedPrivate,
    },
    {
      status: 'Требует уточнения',
      amount: issuesStatistic.RequiresClarificationPrivate,
    },
    {
      status: 'Проблема не решена',
      amount: issuesStatistic.NotResolvedPrivate,
    },
    {
      status: 'Отклонено',
      amount: issuesStatistic.RejectedPrivate,
    },
    {
      status: 'Предоставлено уточнение',
      amount: issuesStatistic.ClarifiedPrivate,
    },
    {
      status: 'Продление срока',
      amount: issuesStatistic.ExtensionTermPrivate,
    },
    {
      status: 'На модерации',
      amount: issuesStatistic.ModerationPrivate,
    },
    {
      status: 'Просрочено',
      amount: issuesStatistic.OverduePrivate,
    },
    {
      status: 'На уточнении',
      amount: issuesStatistic.OnClarification,
    },
    {
      status: 'Уточнено',
      amount: issuesStatistic.ClarificationProvided,
    },
  ].sort((a, b) => (a.amount < b.amount ? 1 : -1)) : [];
  const dataLoading = !issuesStatistic || issuesStatisticData.isFetching;

  return (
    <IssuesByStatus
      executorsOptions={executorsOptions}
      categoriesOptions={categoriesOptions}
      districtsOptions={districtsOptions}
      regionsOptions={regionsOptionsData}
      issues={issues}
      filters={filters}
      setFilters={setFilters}
      dataLoading={dataLoading}
      handleDistrictChange={handleDistrictChange}
      handleRegionChange={handleRegionChange}
      locations={locations}
      regionsLoading={regionsData.isFetching}
      districtsLoading={districtsData.isFetching}
      fieldsLoading={fieldsLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    filters: {
      districtsStatus: districtsData,
      regionsStatus: regionsData,
    },
    statistic: {
      issuesStatisticByStatus: issuesStatisticData,
    },
  } = state;

  return { issuesStatisticData, districtsData, regionsData };
};

export default connect(mapStateToProps, {
  getStatisticIssuesByStatus,
  getRegionsStatus,
  getDistrictsStatus,
})(IssuesByStatusContainer);
