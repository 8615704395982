import axios from '../utils/axios';

export const getStatisticIssuesApplicantsRequest = 'GET_STATICTIC_ISSUES_APPLICANTS_REQUEST';
export const getStatisticIssuesApplicantsSuccess = 'GET_STATICTIC_ISSUES_APPLICANTS_SUCCESS';
export const getStatisticIssuesApplicantsFailure = 'GET_STATICTIC_ISSUES_APPLICANTS_FAILURE';

export const statisticIssuesApplicantsRequest = () => ({
  type: getStatisticIssuesApplicantsRequest,
});

export const statisticIssuesApplicantsSuccess = (items) => ({
  type: getStatisticIssuesApplicantsSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesApplicantsFailure = (error) => ({
  type: getStatisticIssuesApplicantsFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesApplicants = (filters) => (dispatch) => {
  dispatch(statisticIssuesApplicantsRequest());

  return axios('/api/statistic/issues/applicant', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesApplicantsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesApplicantsFailure(err));
    });
};
