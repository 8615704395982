import { combineReducers } from 'redux';
import categories from './categories.reducer';
import {
  districts,
  districtsStatus,
  districtsThemes,
  districtsExecutors,
} from './districts.reducer';
import {
  regions,
  regionsStatus,
  regionsThemes,
  regionsExecutors,
} from './regions.reducer';
import { issuesStatistic, issuesStatisticByStatus } from './issues.reducer';
import issuesApplicantsStatistic from './issuesApplicants.reducer';
import issuesCategoriesStatistic from './issuesCategories.reducer';
import issuesCoexecutorsStatistic from './issuesCoexecutors.reducer';
import issuesCountersStatistic from './issuesCounters.reducer';
import issuesCriminalStatistic from './issuesCriminal.reducer';
import issuesEventTypeStatistic from './issuesEventType.reducer';
import issuesExecutorsStatistic from './issuesExecutors.reducer';
import issuesRegionsStatistic from './issuesRegions.reducer';
import issuesSpheresStatistic from './issuesSpheres.reducer';
import usersStatistic from './users.reducer';
import usersRegionsStatistic from './usersRegions.reducer';
import downloadSection from './downloadReport.reducer';

const mainReducer = combineReducers({
  filters: combineReducers({
    categories,
    districts,
    regions,
    districtsStatus,
    districtsThemes,
    districtsExecutors,
    regionsStatus,
    regionsThemes,
    regionsExecutors,
  }),
  statistic: combineReducers({
    issuesStatistic,
    issuesStatisticByStatus,
    issuesApplicantsStatistic,
    issuesCategoriesStatistic,
    issuesCoexecutorsStatistic,
    issuesCountersStatistic,
    issuesCriminalStatistic,
    issuesEventTypeStatistic,
    issuesExecutorsStatistic,
    issuesRegionsStatistic,
    issuesSpheresStatistic,
    usersStatistic,
    usersRegionsStatistic,
    downloadSection,
  }),
});

export default mainReducer;
