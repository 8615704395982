import axios from '../utils/axios';

export const getCategoriesRequest = 'GET_CATEGORIES_REQUEST';
export const getCategoriesSuccess = 'GET_CATEGORIES_SUCCESS';
export const getCategoriesFailure = 'GET_CATEGORIES_FAILURE';

export const categoriesRequest = () => ({
  type: getCategoriesRequest,
});

export const categoriesSuccess = (items) => ({
  type: getCategoriesSuccess,
  payload: {
    items,
  },
});

export const categoriesFailure = (error) => ({
  type: getCategoriesFailure,
  payload: {
    error,
  },
});

export const getCategories = () => (dispatch) => {
  dispatch(categoriesRequest());

  return axios('/api/issue/category', {
    params: {
      perPage: -1,
    },
  })
    .then((response) => {
      dispatch(categoriesSuccess(response.data.items));
    })
    .catch((err) => {
      dispatch(categoriesFailure(err));
    });
};
