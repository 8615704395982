import {
  getStatisticIssuesCategoriesRequest,
  getStatisticIssuesCategoriesSuccess,
  getStatisticIssuesCategoriesFailure,
} from '../actions/issuesCategories.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesCategoriesStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesCategoriesRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesCategoriesSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesCategoriesFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesCategoriesStatistic;
