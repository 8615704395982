/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssues } from '../../actions/issues.actions';
import { getStatisticUsers } from '../../actions/users.actions';
import { getStatisticIssuesRegions } from '../../actions/issuesRegions.actions';
import GeneralIndicators from './generalIndicators.component';
import LetterIcon from '../../resources/svg/letter.svg';
import UserIcon from '../../resources/svg/user.svg';
import WarningIcon from '../../resources/svg/warning.svg';
import CoatsIcon from '../../resources/svg/coats-of-arms.svg';

function GeneralIndicatorsContainer(props) {
  const {
    dateFilters,
    getStatisticIssues,
    getStatisticUsers,
    getStatisticIssuesRegions,
    usersStatistic,
    issuesStatistic,
    issuesRegionsStatistic,
  } = props;

  const [indicator, setIndicator] = useState('total');
  const [pieData, setPieData] = useState(null);
  const [currentRegion, setCurrentRegion] = useState(null);
  const usersTotal = usersStatistic.items ? usersStatistic.items.total : 0;
  const issuesTotal = issuesStatistic.items ? issuesStatistic.items.total : 0;
  const issuesWithAnswer = issuesStatistic.items ? issuesStatistic.items.WithAnswerPrivate : 0;
  const issuesResolved = issuesStatistic.items ? issuesStatistic.items.ResolvedPrivate : 0;
  const issuesNotResolved = issuesStatistic.items ? issuesStatistic.items.NotResolvedPrivate : 0;
  const dataFetched = !usersStatistic.isFetching && !issuesStatistic.isFetching;
  const indicators = pieData
    || [
      {
        title: 'С ответом (без оценки)',
        value: (issuesWithAnswer - issuesNotResolved - issuesResolved),
        color: 'rgb(255, 152, 0)',
      },
      {
        title: 'Решенных проблем',
        value: issuesResolved,
        color: 'rgb(76, 175, 80)',
      },
      {
        title: 'Нерешенных проблем',
        value: issuesNotResolved,
        color: 'rgba(236, 65, 44, 0.8)',
      },
    ];
  const allIssues = indicators.reduce((a, b) => a + (b.value || 0), 0);

  useEffect(() => {
    getStatisticIssues(dateFilters);
    getStatisticUsers(dateFilters);
    getStatisticIssuesRegions(dateFilters);
  }, [dateFilters, getStatisticIssues, getStatisticUsers, getStatisticIssuesRegions]);

  useEffect(() => {
    const chosenRegion = currentRegion
      && issuesRegions.filter((el) => el.id === currentRegion.id)[0];
    const regionStatistic = chosenRegion ? chosenRegion.statistic : null;

    if (regionStatistic) {
      setPieData([
        {
          title: 'С ответом (без оценки)',
          value: (regionStatistic.answered - regionStatistic.solved - regionStatistic.not_solved),
          color: 'rgb(255, 152, 0)',
        },
        {
          title: 'Решенных проблем',
          value: regionStatistic.solved,
          color: 'rgb(76, 175, 80)',
        },
        {
          title: 'Нерешенных проблем',
          value: regionStatistic.not_solved,
          color: 'rgba(236, 65, 44, 0.8)',
        },
      ]);
    } else {
      setPieData([
        {
          title: 'С ответом (без оценки)',
          value: (issuesWithAnswer - issuesNotResolved - issuesResolved),
          color: 'rgb(255, 152, 0)',
        },
        {
          title: 'Решенных проблем',
          value: issuesResolved,
          color: 'rgb(76, 175, 80)',
        },
        {
          title: 'Нерешенных проблем',
          value: issuesNotResolved,
          color: 'rgba(236, 65, 44, 0.8)',
        },
      ]);
    }
  }, [issuesStatistic, currentRegion, setPieData]);

  const infoCards = [
    {
      title: 'Всего пользователей',
      number: `${usersTotal}`,
      icon: UserIcon,
    },
    {
      title: 'Всего обращений',
      number: `${issuesTotal}`,
      icon: WarningIcon,
    },
    {
      title: 'Обращений с ответом',
      number: `${issuesWithAnswer}`,
      icon: CoatsIcon,
    },
    {
      title: 'Решенных обращений',
      number: `${issuesResolved}`,
      icon: LetterIcon,
    },
  ];
  const issuesRegions = issuesRegionsStatistic.items ? issuesRegionsStatistic.items : [];

  return (
    <GeneralIndicators
      usersTotal={usersTotal}
      issuesTotal={issuesTotal}
      issuesWithAnswer={issuesWithAnswer}
      issuesResolved={issuesResolved}
      dataFetched={dataFetched}
      infoCards={infoCards}
      indicators={indicators}
      allIssues={allIssues}
      indicator={indicator}
      setIndicator={setIndicator}
      issuesRegions={issuesRegions}
      currentRegion={currentRegion}
      setCurrentRegion={setCurrentRegion}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    statistic: {
      issuesStatistic,
      usersStatistic,
      issuesRegionsStatistic,
    },
  } = state;

  return { issuesStatistic, usersStatistic, issuesRegionsStatistic };
};

export default connect(mapStateToProps, {
  getStatisticIssues,
  getStatisticUsers,
  getStatisticIssuesRegions,
})(GeneralIndicatorsContainer);
