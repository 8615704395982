import React from 'react';
import styles from './legendItem.module.css';

function PercentWithLabel({ color, title, data }) {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div
          style={{backgroundColor: color}}
          className={styles.circle}
        />
        <p className={styles.title}>
          {title}
        </p>
      </div>
      {data && (
        <p className={styles.data}>
          {data}
        </p>
      )}
    </div>
  );
}

export default PercentWithLabel;
