import {
  getStatisticIssuesRegionsRequest,
  getStatisticIssuesRegionsSuccess,
  getStatisticIssuesRegionsFailure,
} from '../actions/issuesRegions.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const issuesRegionsStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesRegionsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesRegionsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesRegionsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default issuesRegionsStatistic;
