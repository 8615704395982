import React from 'react';
import cn from 'classnames';
import styles from './mostIssuesRegions.module.css';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function MostIssuesRegions(props) {
  const {
    regionsStatisticData,
    dataLoading,
  } = props;

  return (
    <section
      className={cn(styles.container, {
        'loading-container': dataLoading,
      })}
    >
      <h2 className={styles.title}>
        Регионы с наибольшим количеством обращений
      </h2>
      <div className={styles.tables}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td className={styles.tdNumber} />
              <td className={cn(styles.theadTd, styles.theadTdRegion)}>Регион</td>
              <td className={styles.theadTd}>Кол-во пользователей</td>
              <td className={styles.theadTd}>Кол-во обращений</td>
              <td className={styles.theadTd}>Кол-во с ответом</td>
              <td className={styles.theadTd}>Кол-во решенных</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {regionsStatisticData.map((region, index) => (
              <tr className={styles.tr} key={region.id}>
                <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                <td className={cn(styles.td, styles.tdRegion)}>{region.region}</td>
                <td className={styles.td}>{region.users}</td>
                <td className={styles.td}>{region.statistic.total}</td>
                <td className={styles.td}>{region.statistic.answered}</td>
                <td className={styles.td}>{region.statistic.solved}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CssLoader />
    </section>
  );
}

export default MostIssuesRegions;
