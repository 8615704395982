import React from 'react';

function Region(props) {
  const {
    coordinates,
    color,
    opacity,
    stroke,
    onClick,
  } = props;

  return (
    <path
      opacity={opacity}
      d={coordinates}
      fill={color}
      stroke={stroke}
      onClick={onClick}
    />
  );
}

export default Region;
