import axios from '../utils/axios';

export const getStatisticIssuesCriminalRequest = 'GET_STATICTIC_ISSUES_CRIMINAL_REQUEST';
export const getStatisticIssuesCriminalSuccess = 'GET_STATICTIC_ISSUES_CRIMINAL_SUCCESS';
export const getStatisticIssuesCriminalFailure = 'GET_STATICTIC_ISSUES_CRIMINAL_FAILURE';

export const statisticIssuesCriminalRequest = () => ({
  type: getStatisticIssuesCriminalRequest,
});

export const statisticIssuesCriminalSuccess = (items) => ({
  type: getStatisticIssuesCriminalSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesCriminalFailure = (error) => ({
  type: getStatisticIssuesCriminalFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesCriminal = (filters) => (dispatch) => {
  dispatch(statisticIssuesCriminalRequest());

  return axios('/api/statistic/issues/criminal', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesCriminalSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesCriminalFailure(err));
    });
};
