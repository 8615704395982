import React from 'react';
import styles from './App.module.css';
import Analytics from '../analytics/analytics.container';

function App() {
  return (
    <div className={styles.container}>
      <Analytics />
    </div>
  );
}

export default App;
