import React from 'react';
import styles from './analytics.module.css';
import GeneralIndicators from '../generalIndicators/generalIndicators.container';
import IssueDeadlines from '../issueDeadlines/issueDeadlines.container';
import IssuesAmount from '../issuesAmount/issuesAmount.container';
import IssuesByStatus from '../issuesByStatus/issuesByStatus.container';
import WhoIsApplicant from '../whoIsApplicant/whoIsApplicant.container';
import MostIssuesRegions from '../mostIssuesRegions/mostIssuesRegions.container';
import PopularSpheres from '../popularSpheres/popularSpheres.container';
import PopularThemes from '../popularThemes/popularThemes.container';
import ExecutorsRating from '../executorsRating/executorsRating.container';
import ReportDownloadContainer from '../reportDownload/reportDownload.container';
import DatePickerRange from '../../ui-kit/datePicker/datePickerRange.component';
import Select from '../../ui-kit/select/select.component';

function Analytics(props) {
  const {
    filters,
    regionsOptions,
    districtsOptions,
    categoriesOptions,
    executorsOptions,
    fieldsLoading,
    handleDatesBlur,
    handleBack,
    dateId,
    typesOptions,
    handleTypeChange,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.buttonWrapper}>
        <button
          type="button"
          className={styles.button}
          onClick={() => handleBack()}
        >
          Вернуться назад
        </button>
      </div>
      <section className={styles.header}>
        <div className={styles.wrapperHeader}>
          <div className={styles.headerText}>
            <h1 className={styles.title}>
              Статистика
            </h1>
            <p>
              Основные показатели по работе с обращениями
            </p>
          </div>
          <div className={styles.headerSelectsContainer}>
            <Select
              className={styles.headerSelectType}
              placeholder="Тип обращения"
              options={typesOptions}
              defaultValue={typesOptions[0]}
              onChange={(type) => handleTypeChange(type.value)}
            />
            <DatePickerRange
              onBlur={handleDatesBlur}
              id={dateId}
              className={styles.filterDates}
            />
          </div>
        </div>
      </section>
      <div className={styles.wrapper}>
        <GeneralIndicators
          dateFilters={filters}
        />
        <MostIssuesRegions
          dateFilters={filters}
        />
        <IssueDeadlines
          dateFilters={filters}
          executorsOptions={executorsOptions}
        />
        <IssuesByStatus
          dateFilters={filters}
          districtsOptions={districtsOptions}
          regionsOptions={regionsOptions}
          categoriesOptions={categoriesOptions}
          executorsOptions={executorsOptions}
          fieldsLoading={fieldsLoading}
        />
        <WhoIsApplicant
          dateFilters={filters}
        />
        <PopularSpheres
          dateFilters={filters}
        />
        <IssuesAmount
          dateFilters={filters}
        />
        <PopularThemes
          dateFilters={filters}
          districtsOptions={districtsOptions}
          regionsOptions={regionsOptions}
          fieldsLoading={fieldsLoading}
        />
        <ExecutorsRating
          dateFilters={filters}
          districtsOptions={districtsOptions}
          regionsOptions={regionsOptions}
          categoriesOptions={categoriesOptions}
          fieldsLoading={fieldsLoading}
        />
        <ReportDownloadContainer
          filters={filters}
        />
      </div>
    </div>
  );
}

export default Analytics;
