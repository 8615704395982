/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesCategories } from '../../actions/issuesCategories.actions';
import { getRegionsThemes } from '../../actions/getRegions.actions';
import { getDistrictsThemes } from '../../actions/getDistricts.actions';
import PopularThemes from './popularThemes.component';

function PopularThemesContainer(props) {
  const {
    dateFilters,
    districtsOptions,
    regionsOptions,
    issuesCategoriesStatistic,
    getStatisticIssuesCategories,
    getRegionsThemes,
    getDistrictsThemes,
    regionsData,
    districtsData,
    fieldsLoading,
  } = props;

  const [filters, setFilters] = useState({
    order_by_statistic: 'total',
    order_direction: 'desc',
    district: null,
    region: null,
  });
  const [locations, setLocations] = useState({
    district: null,
    region: null,
  });
  const [regions, setRegions] = useState(null);

  useEffect(() => {
    if (!districtsData.isFetching && !regionsData.isFetching) {
      getStatisticIssuesCategories({ ...dateFilters, ...filters });
    }
  }, [dateFilters, filters, getStatisticIssuesCategories]);

  useEffect(() => {
    if (districtsData.items && !districtsData.isFetching) {
      setLocations({
        ...locations,
        district: { label: districtsData.items.name, value: districtsData.items.id },
      });
      setFilters({ ...filters, district: districtsData.items.id });
      getRegionsThemes(districtsData.items.id);
    }
  }, [districtsData]);

  useEffect(() => {
    if (regionsData.items && !regionsData.isFetching) {
      setRegions(regionsData.items);
      setFilters({ ...filters, region: locations.region ? locations.region.value : null });
    }
  }, [regionsData]);

  const handleDistrictChange = (district) => {
    if (district.value) {
      getRegionsThemes(district.value);
    }
    setLocations({ region: null, district });
    setFilters({ ...filters, district: district.value, region: null });
  };

  const handleRegionChange = (region) => {
    if (region.value) {
      getDistrictsThemes(region.value);
    }
    setLocations({ ...locations, region });
    setFilters({ ...filters, region: region.value });
  };

  const regionsOptionsData = regions && locations.district.value
    ? [
      { label: 'Все', value: null },
      ...Object.keys(regions).map(
        (k) => ({ label: regions[k].name, value: regions[k].id }),
      ),
    ]
    : regionsOptions;
  const issuesCategories = issuesCategoriesStatistic.items
    ? issuesCategoriesStatistic.items.slice(0, 5)
    : [];
  const dataLoading = !issuesCategories.length || issuesCategoriesStatistic.isFetching;

  return (
    <PopularThemes
      issuesCategories={issuesCategories}
      districtsOptions={districtsOptions}
      regionsOptions={regionsOptionsData}
      dataLoading={dataLoading}
      handleDistrictChange={handleDistrictChange}
      handleRegionChange={handleRegionChange}
      locations={locations}
      regionsLoading={regionsData.isFetching}
      districtsLoading={districtsData.isFetching}
      fieldsLoading={fieldsLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    filters: {
      districtsThemes: districtsData,
      regionsThemes: regionsData,
    },
    statistic: {
      issuesCategoriesStatistic,
    },
  } = state;

  return { issuesCategoriesStatistic, districtsData, regionsData };
};

export default connect(mapStateToProps, {
  getStatisticIssuesCategories,
  getRegionsThemes,
  getDistrictsThemes,
})(PopularThemesContainer);
