import {
  getDistrictsRequest,
  getDistrictsSuccess,
  getDistrictsFailure,
  getDistrictsStatusRequest,
  getDistrictsStatusSuccess,
  getDistrictsStatusFailure,
  getDistrictsThemesRequest,
  getDistrictsThemesSuccess,
  getDistrictsThemesFailure,
  getDistrictsExecutorsRequest,
  getDistrictsExecutorsSuccess,
  getDistrictsExecutorsFailure,
} from '../actions/getDistricts.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

export const districts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getDistrictsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getDistrictsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getDistrictsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const districtsStatus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getDistrictsStatusRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getDistrictsStatusSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getDistrictsStatusFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const districtsThemes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getDistrictsThemesRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getDistrictsThemesSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getDistrictsThemesFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const districtsExecutors = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getDistrictsExecutorsRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getDistrictsExecutorsSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getDistrictsExecutorsFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};
