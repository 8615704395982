import {
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
} from '../actions/getCategories.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

const categories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getCategoriesRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getCategoriesSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getCategoriesFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default categories;
