import {
  getStatisticIssuesRequest,
  getStatisticIssuesSuccess,
  getStatisticIssuesFailure,
  getStatisticIssuesByStatusRequest,
  getStatisticIssuesByStatusSuccess,
  getStatisticIssuesByStatusFailure,
} from '../actions/issues.actions';

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  error: null,
};

export const issuesStatistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export const issuesStatisticByStatus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getStatisticIssuesByStatusRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getStatisticIssuesByStatusSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getStatisticIssuesByStatusFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};
