import React from 'react';
import cn from 'classnames';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import styles from './issuesAmount.module.css';
import LegendItem from '../../ui-kit/legendItem/legendItem.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function IssuesAmount(props) {
  const {
    criminalCases,
    criminalCasesAmount,
    events,
    eventsAmount,
    eventTypeLoading,
    criminalIssuesLoading,
  } = props;

  return (
    <section className={styles.containerWrapper}>
      <div
        className={cn(styles.container, {
          'loading-container': eventTypeLoading,
        })}
      >
        <h2 className={styles.title}>
          Количество обращений по типу
          <br />
          мероприятия
        </h2>
        <div className={styles.chart}>
          <ReactMinimalPieChart
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={events}
            label={false}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={40}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={2}
            radius={50}
            rounded={false}
            startAngle={0}
            viewBoxSize={[
              100,
              100,
            ]}
          />
        </div>
        <ul className={styles.legend}>
          {events.map((event) => (
            <li key={event.color} className={styles.legend__item}>
              <LegendItem
                color={event.color}
                title={event.title}
                data={`${event.value} (${(((event.value / eventsAmount) || 0) * 100).toFixed(1)}%)`}
              />
            </li>
          ))}
        </ul>
        <CssLoader />
      </div>
      <div
        className={cn(styles.container, {
          'loading-container': criminalIssuesLoading,
        })}
      >
        <h2 className={styles.title}>
          Количество обращений по стадиям уголовного дела
        </h2>
        <div className={styles.chart}>
          <ReactMinimalPieChart
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={criminalCases}
            label={false}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={40}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={2}
            radius={50}
            rounded={false}
            startAngle={0}
            viewBoxSize={[
              100,
              100,
            ]}
          />
        </div>
        <ul className={styles.legend}>
          {criminalCases.map((criminalCase) => (
            <li key={criminalCase.color} className={styles.legend__item}>
              <LegendItem
                color={criminalCase.color}
                title={criminalCase.title}
                data={`${criminalCase.value} (${(((criminalCase.value / criminalCasesAmount) || 0) * 100).toFixed(1)}%)`}
              />
            </li>
          ))}
        </ul>
        <CssLoader />
      </div>
    </section>
  );
}

export default IssuesAmount;
