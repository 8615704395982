import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesCounters } from '../../actions/issuesCounters.actions';
import IssueDeadlines from './issueDeadlines.component';

const GEN_PROKURATURA_ID = '9050';
const APPEAL_NAME = 'appeal';

function IssueDeadlinesContainer(props) {
  const {
    dateFilters,
    executorsOptions,
    getStatisticIssuesCounters,
    issuesCountersStatistic,
  } = props;

  const [filters, setFilters] = useState({
    executor: null,
  });

  useEffect(() => {
    getStatisticIssuesCounters({ ...dateFilters, ...filters });
  }, [filters, dateFilters, getStatisticIssuesCounters]);

  useEffect(() => {
    if (dateFilters.type === APPEAL_NAME) {
      const executor = executorsOptions.filter((ex) => ex.value === GEN_PROKURATURA_ID)[0];
      setFilters((filters) => ({ ...filters, executor: executor.value }));
    }
  }, [dateFilters.type, executorsOptions]);

  const countersData = issuesCountersStatistic.items ? issuesCountersStatistic.items : [];
  const answersExpired = countersData.reduce((a, b) => +b.answers_expired + a, 0);
  const answersNotExpired = countersData.reduce((a, b) => +b.answers_not_expired + a, 0);
  const inProgress = countersData.reduce((a, b) => +b.progress + a, 0);
  const progressExpired = countersData.reduce((a, b) => +b.progress_expired + a, 0);
  const progressNotExpired = countersData.reduce((a, b) => +b.progress_not_expired + a, 0);
  const answeredIssues = [
    {
      title: 'С ответом в срок:',
      value: +answersNotExpired,
      color: '#2196f3',
    },
    {
      title: 'Позже срока:',
      value: +answersExpired,
      color: '#9c27b0',
    },
  ];
  const waitingIssues = [
    {
      title: 'Просрочены:',
      value: +progressExpired,
      color: '#ec412c',
    },
    {
      title: 'Не просрочены:',
      value: +progressNotExpired,
      color: '#4caf50',
    },
  ];
  const answeredIssuesAmount = answeredIssues.reduce((a, b) => a + (b.value || 0), 0);
  const waitingIssuesAmount = waitingIssues.reduce((a, b) => a + (b.value || 0), 0);
  const answerTime = issuesCountersStatistic.items
    ? (countersData[0].answer_time / 86400).toFixed(0)
    : 0;
  const numberOfExpired = issuesCountersStatistic.items
    ? countersData
      .filter((el) => el.avg_time_expired !== 0)
      .length
    : null;
  const averageTimeExpired = issuesCountersStatistic.items
    ? (countersData
      .reduce((a, b) => +b.avg_time_expired + a, 0) / 86400 / (numberOfExpired || 1))
      .toFixed(0)
    : 0;
  const numberOfNotExpired = issuesCountersStatistic.items
    ? countersData
      .filter((el) => el.avg_time_not_expired !== 0)
      .length
    : null;
  const averageTimeNotExpired = issuesCountersStatistic.items
    ? (countersData
      .reduce((a, b) => +b.avg_time_not_expired + a, 0) / 86400 / (numberOfNotExpired || 1))
      .toFixed(0)
    : 0;
  const dataLoading = !countersData.length || issuesCountersStatistic.isFetching;

  const responceDays = [
    {
      title: 'среднее время, отведенное на ответ',
      value: answerTime,
    },
    {
      title: 'среднее время ответа на обращение, без продления срока',
      value: averageTimeNotExpired,
    },
    {
      title: 'среднее время ответа на обращение, с продлением срока',
      value: averageTimeExpired,
    },
  ];

  const graphData = [{
    name: '',
    data: [answerTime, averageTimeNotExpired, averageTimeExpired],
  }];

  const filteredExecutorsOptions = dateFilters.type === APPEAL_NAME
    ? executorsOptions.filter((ex) => ex.value === GEN_PROKURATURA_ID)
    : executorsOptions;

  return (
    <IssueDeadlines
      filters={filters}
      setFilters={setFilters}
      inProgress={inProgress}
      answeredIssuesAmount={answeredIssuesAmount}
      waitingIssuesAmount={waitingIssuesAmount}
      responceDays={responceDays}
      graphData={graphData}
      executorsOptions={filteredExecutorsOptions}
      barChartOption={barChartOption}
      answeredIssues={answeredIssues}
      waitingIssues={waitingIssues}
      dataLoading={dataLoading}
      executor={executorsOptions.filter((ex) => ex.value === filters.executor)}
    />
  );
}

const barChartOption = {
  options: {
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '20%',
      },
    },
    xaxis: {
      tickAmount: 7,
      categories: [
        'Среднее время, отведенное на ответ',
        'Среднее время ответа на обращение, без продления срока',
        'Среднее время ответа на обращение, с продлением срока',
      ],
      axisBorder: {
        show: true,
        color: '#c4c4c4',
        height: 1,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#c4c4c4',
        height: 1,
        width: '100%',
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    colors: ['#ef8f35', '#2196f3', '#9c27b0'],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  },
};

const mapStateToProps = (state) => {
  const {
    statistic: {
      issuesCountersStatistic,
    },
  } = state;

  return { issuesCountersStatistic };
};

export default connect(mapStateToProps, {
  getStatisticIssuesCounters,
})(IssueDeadlinesContainer);
