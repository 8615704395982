/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatisticIssuesExecutors } from '../../actions/issuesExecutors.actions';
import { getStatisticIssuesCoexecutors } from '../../actions/issuesCoexecutors.actions';
import { getRegionsExecutors } from '../../actions/getRegions.actions';
import { getDistrictsExecutors } from '../../actions/getDistricts.actions';
import ExecutorsRating from './executorsRating.component';

function ExecutorsRatingContainer(props) {
  const {
    dateFilters,
    districtsOptions,
    regionsOptions,
    categoriesOptions,
    executorsStatisticData,
    coexecutorsStatisticData,
    getStatisticIssuesExecutors,
    getStatisticIssuesCoexecutors,
    getRegionsExecutors,
    getDistrictsExecutors,
    regionsData,
    districtsData,
    fieldsLoading,
  } = props;

  const [filters, setFilters] = useState({
    order_by_statistic: 'total',
    order_direction: 'desc',
    district: null,
    region: null,
    category: null,
    executor: null,
  });
  const [locations, setLocations] = useState({
    district: null,
    region: null,
  });
  const [regions, setRegions] = useState(null);

  useEffect(() => {
    if (!districtsData.isFetching && !regionsData.isFetching) {
      getStatisticIssuesExecutors({ ...dateFilters, ...filters });
      getStatisticIssuesCoexecutors({ ...dateFilters, ...filters });
    }
  }, [dateFilters, filters, getStatisticIssuesExecutors, getStatisticIssuesCoexecutors]);

  useEffect(() => {
    if (districtsData.items && !districtsData.isFetching) {
      setLocations({
        ...locations,
        district: { label: districtsData.items.name, value: districtsData.items.id },
      });
      setFilters({ ...filters, district: districtsData.items.id });
      getRegionsExecutors(districtsData.items.id);
    }
  }, [districtsData]);

  useEffect(() => {
    if (regionsData.items && !regionsData.isFetching) {
      setRegions(regionsData.items);
      setFilters({ ...filters, region: locations.region ? locations.region.value : null });
    }
  }, [regionsData]);

  const handleDistrictChange = (district) => {
    if (district.value) {
      getRegionsExecutors(district.value);
    }
    setLocations({ region: null, district });
    setFilters({ ...filters, district: district.value, region: null });
  };

  const handleRegionChange = (region) => {
    if (region.value) {
      getDistrictsExecutors(region.value);
    }
    setLocations({ ...locations, region });
    setFilters({ ...filters, region: region.value });
  };

  const regionsOptionsData = regions && locations.district.value
    ? [
      { label: 'Все', value: null },
      ...Object.keys(regions).map(
        (k) => ({ label: regions[k].name, value: regions[k].id }),
      ),
    ]
    : regionsOptions;
  const executors = executorsStatisticData.items ? executorsStatisticData.items : [];
  const coexecutors = coexecutorsStatisticData.items ? coexecutorsStatisticData.items : [];

  const executorsLoading = !executors.length || executorsStatisticData.isFetching;
  const coexecutorsLoading = !coexecutors.length || coexecutorsStatisticData.isFetching;

  return (
    <ExecutorsRating
      categoriesOptions={categoriesOptions}
      regionsOptions={regionsOptionsData}
      districtsOptions={districtsOptions}
      filters={filters}
      setFilters={setFilters}
      executors={executors}
      coexecutors={coexecutors}
      executorsLoading={executorsLoading}
      coexecutorsLoading={coexecutorsLoading}
      handleDistrictChange={handleDistrictChange}
      handleRegionChange={handleRegionChange}
      locations={locations}
      regionsLoading={regionsData.isFetching}
      districtsLoading={districtsData.isFetching}
      fieldsLoading={fieldsLoading}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    filters: {
      districtsExecutors: districtsData,
      regionsExecutors: regionsData,
    },
    statistic: {
      issuesExecutorsStatistic: executorsStatisticData,
      issuesCoexecutorsStatistic: coexecutorsStatisticData,
    },
  } = state;

  return {
    executorsStatisticData,
    coexecutorsStatisticData,
    districtsData,
    regionsData,
  };
};

export default connect(mapStateToProps, {
  getStatisticIssuesExecutors,
  getStatisticIssuesCoexecutors,
  getRegionsExecutors,
  getDistrictsExecutors,
})(ExecutorsRatingContainer);
