import axios from 'axios';

const url = window.location.href;
const tokenParam = '?authToken=';
const token = url.indexOf(tokenParam) !== -1
  ? url.slice(url.indexOf(tokenParam) + tokenParam.length)
  : null;

if (!token) {
  window.location.href = 'https://zabiznes.org/panel/login';
} else {
  localStorage.setItem('authToken', token);
}

if (localStorage.authToken) {
  axios.defaults.headers.common['x-auth-token'] = localStorage.authToken;
}

axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    window.location.href = 'https://zabiznes.org/panel/login';
  }

  return Promise.reject(error);
});

export default axios;
