import axios from '../utils/axios';

export const getStatisticIssuesCountersRequest = 'GET_STATICTIC_ISSUES_COUNTERS_REQUEST';
export const getStatisticIssuesCountersSuccess = 'GET_STATICTIC_ISSUES_COUNTERS_SUCCESS';
export const getStatisticIssuesCountersFailure = 'GET_STATICTIC_ISSUES_COUNTERS_FAILURE';

export const statisticIssuesCountesRequest = () => ({
  type: getStatisticIssuesCountersRequest,
});

export const statisticIssuesCountersSuccess = (items) => ({
  type: getStatisticIssuesCountersSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesCountersFailure = (error) => ({
  type: getStatisticIssuesCountersFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesCounters = (filters) => (dispatch) => {
  dispatch(statisticIssuesCountesRequest());

  return axios('/api/statistic/issues/counters', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesCountersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesCountersFailure(err));
    });
};
