import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  generateAnalyticsIssuesFullReport,
  generateAnalyticsIssuesComfortReport,
  generateAnalyticsUsersReport,
  checkAnalyticsIssuesFullReportReady,
  checkAnalyticsIssuesComfortReportReady,
  checkAnalyticsUsersReportReady,
  fetchListValuesForReport,
  generateDetailedReport,
  generateAnalyticsIssuesTermsReport,
  generateAnalyticsIssuesNotSolvedReport,
  downloadByToken,
  getDistrictsDownloadSection,
  getRegionsDownloadSection,
  getDistrictsByRegionDownloadSection,
} from '../../actions/downloadStatistics.actions';
import ReportDownload from './reportDownload.component';

const checkReportIntervalTime = 1000;
const checkReportMaxTime = 300000;
const relatedReportLists = {
  row: 'column',
  column: 'row',
};

class ReportDownloadContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isReportListFetching: false,
      fullReportGeneratingInProgress: false,
      comfortReportGeneratingInProgress: false,
      termsReportGeneratingInProgress: false,
      issuesNotSolvedReportGeneratingInProgress: false,
      detailedReportGeneratingInProgress: false,
      usersReportGeneratingInProgress: false,
      reportForm: {
        created_from: null,
        created_to: null,
        row: null,
        column: null,
        district: null,
        region: null,
        type: this.props.filters.type,
      },
      reportFormFull: {
        created_from: null,
        created_to: null,
        type: this.props.filters.type,
      },
      reportFormComfort: {
        created_from: null,
        created_to: null,
        type: this.props.filters.type,
      },
      reportFormTerms: {
        created_from: null,
        created_to: null,
        type: this.props.filters.type,
      },
      reportValues: {
        created_from: null,
        created_to: null,
        created_from_full: null,
        created_to_full: null,
        created_from_comfort: null,
        created_to_comfort: null,
        row: null,
        column: null,
        district: null,
        region: null,
      },
      usersReportForm: {
        created_from: null,
        created_to: null,
      },
      usersValues: {
        created_from: null,
        created_to: null,
        created_from_full: null,
        created_to_full: null,
        created_from_comfort: null,
        created_to_comfort: null,
        row: null,
        column: null,
        district: null,
        region: null,
      },
    };

    this.handleChangeDateFilter = this.handleChangeDateFilter.bind(this);
    this.handleChangeDateFilterFull = this.handleChangeDateFilterFull.bind(this);
    this.handleChangeDateFilterComfort = this.handleChangeDateFilterComfort.bind(this);
    this.handleChangeDateFilterTerms = this.handleChangeDateFilterTerms.bind(this);
    this.handleChangeDateFilterUsers = this.handleChangeDateFilterUsers.bind(this);
    this.downloadDetailedReport = this.downloadDetailedReport.bind(this);
    this.downloadIssuesFullReport = this.downloadIssuesFullReport.bind(this);
    this.downloadIssuesComfortReport = this.downloadIssuesComfortReport.bind(this);
    this.downloadUsersReport = this.downloadUsersReport.bind(this);
    this.downloadIssuesTermsReport = this.downloadIssuesTermsReport.bind(this);
    this.downloadIssuesNotSolvedReport = this.downloadIssuesNotSolvedReport.bind(this);
    this.handleUpdateListValue = this.handleUpdateListValue.bind(this);
    this.handleDistrictChange = this.handleDistrictChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchListValuesForReport('row', 'all');
    this.props.fetchListValuesForReport('column', 'all');

    this.props.getDistrictsDownloadSection();
    this.props.getRegionsDownloadSection(null);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters.type !== prevProps.filters.type) {
      const {
        reportForm,
        reportFormFull,
        reportFormTerms,
        reportFormComfort,
      } = this.state;

      /* eslint-disable-next-line */
      this.setState({
        reportForm: {
          ...reportForm,
          type: this.props.filters.type,
        },
        reportFormFull: {
          ...reportFormFull,
          type: this.props.filters.type,
        },
        reportFormTerms: {
          ...reportFormTerms,
          type: this.props.filters.type,
        },
        reportFormComfort: {
          ...reportFormComfort,
          type: this.props.filters.type,
        },
      });
    }
  }

  handleChangeDateFilter(start, end) {
    const dates = {};
    const datesVal = {};
    const { reportValues } = this.state;
    const { reportForm } = this.state;
    const startStart = start ? start.clone().hour(0).minute(0).second(0) : null;
    const now = moment();
    const endEnd = end ?
      (
        end.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ?
          now.clone() :
          end.clone().hour(23).minute(59).second(59)
      ) :
      null;

    if (startStart) {
      dates.created_from = startStart;
      datesVal.created_from = startStart.format('X');
    }
    if (endEnd) {
      dates.created_to = endEnd;
      datesVal.created_to = endEnd.format('X');
    }
    if (!startStart && !endEnd) {
      dates.created_from = null;
      datesVal.created_from = null;
      dates.created_to = null;
      datesVal.created_to = null;
    }

    this.setState({
      reportValues: {
        ...reportValues,
        ...dates,
      },
      reportForm: {
        ...reportForm,
        ...datesVal,
      },
    });
  }

  handleChangeDateFilterFull(start, end) {
    const dates = {};
    const datesVal = {};
    const { reportValues } = this.state;
    const { reportFormFull } = this.state;
    const startStart = start ? start.clone().hour(0).minute(0).second(0) : null;
    const now = moment();
    const endEnd = end ?
      (
        end.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ?
          now.clone() :
          end.clone().hour(23).minute(59).second(59)
      ) :
      null;

    if (startStart) {
      dates.created_from_full = startStart;
      datesVal.created_from = startStart.format('X');
    }
    if (endEnd) {
      dates.created_to_full = endEnd;
      datesVal.created_to = endEnd.format('X');
    }
    if (!startStart && !endEnd) {
      dates.created_from_full = null;
      datesVal.created_from = null;
      dates.created_to_full = null;
      datesVal.created_to = null;
    }

    this.setState({
      reportValues: {
        ...reportValues,
        ...dates,
      },
      reportFormFull: {
        ...reportFormFull,
        ...datesVal,
      },
    });
  }

  handleChangeDateFilterComfort(start, end) {
    const dates = {};
    const datesVal = {};
    const { reportValues } = this.state;
    const { reportFormComfort } = this.state;
    const startStart = start ? start.clone().hour(0).minute(0).second(0) : null;
    const now = moment();
    const endEnd = end ?
      (
        end.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ?
          now.clone() :
          end.clone().hour(23).minute(59).second(59)
      ) :
      null;

    if (startStart) {
      dates.created_from_comfort = startStart;
      datesVal.created_from = startStart.format('X');
    }
    if (endEnd) {
      dates.created_to_comfort = endEnd;
      datesVal.created_to = endEnd.format('X');
    }
    if (!startStart && !endEnd) {
      dates.created_from_comfort = null;
      datesVal.created_from = null;
      dates.created_to_comfort = null;
      datesVal.created_to = null;
    }

    this.setState({
      reportValues: {
        ...reportValues,
        ...dates,
      },
      reportFormComfort: {
        ...reportFormComfort,
        ...datesVal,
      },
    });
  }

  handleChangeDateFilterTerms(start, end) {
    const dates = {};
    const datesVal = {};
    const { reportValues } = this.state;
    const { reportFormTerms } = this.state;
    const startStart = start ? start.clone().hour(0).minute(0).second(0) : null;
    const now = moment();
    const endEnd = end ?
      (
        end.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ?
          now.clone() :
          end.clone().hour(23).minute(59).second(59)
      ) :
      null;

    if (startStart) {
      dates.created_from_terms = startStart;
      datesVal.created_from = startStart.format('X');
    }
    if (endEnd) {
      dates.created_to_terms = endEnd;
      datesVal.created_to = endEnd.format('X');
    }
    if (!startStart && !endEnd) {
      dates.created_from_terms = null;
      datesVal.created_from = null;
      dates.created_to_terms = null;
      datesVal.created_to = null;
    }

    this.setState({
      reportValues: {
        ...reportValues,
        ...dates,
      },
      reportFormTerms: {
        ...reportFormTerms,
        ...datesVal,
      },
    });
  }

  handleChangeDateFilterUsers(start, end) {
    const dates = {};
    const datesVal = {};
    const { usersValues } = this.state;
    const { usersReportForm } = this.state;
    const startStart = start ? start.clone().hour(0).minute(0).second(0) : null;
    const now = moment();
    const endEnd = end ?
      (
        end.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ?
          now.clone() :
          end.clone().hour(23).minute(59).second(59)
      ) :
      null;

    if (startStart) {
      dates.created_from_comfort = startStart;
      datesVal.created_from = startStart.format('X');
    }
    if (endEnd) {
      dates.created_to_comfort = endEnd;
      datesVal.created_to = endEnd.format('X');
    }
    if (!startStart && !endEnd) {
      dates.created_from_comfort = null;
      datesVal.created_from = null;
      dates.created_to_comfort = null;
      datesVal.created_to = null;
    }

    this.setState({
      usersValues: {
        ...usersValues,
        ...dates,
      },
      usersReportForm: {
        ...usersReportForm,
        ...datesVal,
      },
    });
  }

  handleUpdateListValue(list, value) {
    const { reportValues } = this.state;
    const { reportForm } = this.state;

    this.setState({
      reportValues: {
        ...reportValues,
        [list]: value,
      },
      reportForm: {
        ...reportForm,
        [list]: value && value.value,
      },
    });

    this.setState({
      // eslint-disable-next-line react/no-unused-state
      isReportListFetching: true,
    });
    this.props.fetchListValuesForReport(relatedReportLists[list], value ? value.value : 'all')
      .finally(() => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          isReportListFetching: false,
        });
      });
  }

  handleDistrictChange(value) {
    const { reportValues } = this.state;
    const { reportForm } = this.state;

    this.props.getRegionsDownloadSection(value.value);
    this.setState({
      reportValues: {
        ...reportValues,
        district: value,
        region: null,
      },
      reportForm: {
        ...reportForm,
        district: value.value,
        region: null,
      },
    });
  }

  handleRegionChange(value) {
    const { reportValues } = this.state;
    const { reportForm } = this.state;

    this.props.getDistrictsByRegionDownloadSection(value.value)
      .then((response) => {
        this.setState({
          reportValues: {
            ...reportValues,
            region: value,
            district: response.id ?
              { value: response.id, label: response.name } :
              reportValues.district,
          },
          reportForm: {
            ...reportForm,
            region: value.value,
            district: response.id || reportForm.district,
          },
        });
      })
      .catch(() => {
        this.setState({
          reportValues: {
            ...reportValues,
            region: null,
            district: null,
          },
          reportForm: {
            ...reportForm,
            region: null,
            district: null,
          },
        });
      });
  }

  downloadDetailedReport() {
    if (this.state.detailedReportGeneratingInProgress) {
      return false;
    }

    this.setState({
      detailedReportGeneratingInProgress: true,
    });

    return this.props.generateDetailedReport(this.state.reportForm)
      .then((token) => this.props.downloadByToken(token))
      .catch((err) => {
        console.error('error ', err);
      })
      .finally(() => {
        this.setState({
          detailedReportGeneratingInProgress: false,
        });
      });
  }

  downloadIssuesTermsReport() {
    if (this.state.termsReportGeneratingInProgress) {
      return false;
    }

    this.setState({
      termsReportGeneratingInProgress: true,
    });

    return this.props.generateAnalyticsIssuesTermsReport(this.state.reportFormTerms)
      .then((token) => this.props.downloadByToken(token))
      .catch((err) => {
        console.error('error ', err);
      })
      .finally(() => {
        this.setState({
          termsReportGeneratingInProgress: false,
        });
      });
  }

  downloadIssuesNotSolvedReport() {
    if (this.state.issuesNotSolvedReportGeneratingInProgress) {
      return false;
    }

    this.setState({
      issuesNotSolvedReportGeneratingInProgress: true,
    });

    return this.props.generateAnalyticsIssuesNotSolvedReport()
      .then((token) => this.props.downloadByToken(token))
      .catch((err) => {
        console.error('error ', err);
      })
      .finally(() => {
        this.setState({
          issuesNotSolvedReportGeneratingInProgress: false,
        });
      });
  }

  downloadIssuesFullReport(type) {
    if (this.state.fullReportGeneratingInProgress) {
      return false;
    }

    this.setState({
      fullReportGeneratingInProgress: true,
    });

    return this.props.generateAnalyticsIssuesFullReport(type, this.state.reportFormFull)
      .then((response) => {
        if (response === true) {
          return new Promise((resolve, reject) => {
            this.interval = setInterval(() => {
              const filters = this.state.reportFormFull;
              this.props.checkAnalyticsIssuesFullReportReady(filters)
                .then((response) => {
                  if (response) {
                    clearInterval(this.interval);

                    resolve(response);
                  }
                });
            }, checkReportIntervalTime);

            setTimeout(() => {
              clearInterval(this.interval);

              // eslint-disable-next-line prefer-promise-reject-errors
              reject('max time exceeded');
            }, checkReportMaxTime);
          });
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Report generation error');
      })
      .then((token) => {
        this.props.downloadByToken(token);
      })
      .catch((err) => {
        console.error('error ', err);
      })
      .finally(() => {
        this.setState({
          fullReportGeneratingInProgress: false,
        });
      });
  }

  downloadIssuesComfortReport(type) {
    if (this.state.comfortReportGeneratingInProgress) {
      return false;
    }

    this.setState({
      comfortReportGeneratingInProgress: true,
    });

    return this.props.generateAnalyticsIssuesComfortReport(type, this.state.reportFormComfort)
      .then((response) => {
        if (response === true) {
          return new Promise((resolve, reject) => {
            this.interval = setInterval(() => {
              const filters = this.state.reportFormComfort;
              this.props.checkAnalyticsIssuesComfortReportReady(filters)
                .then((response) => {
                  if (response) {
                    clearInterval(this.interval);

                    resolve(response);
                  }
                });
            }, checkReportIntervalTime);

            setTimeout(() => {
              clearInterval(this.interval);

              // eslint-disable-next-line prefer-promise-reject-errors
              reject('max time exceeded');
            }, checkReportMaxTime);
          });
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Report generation error');
      })
      .then((token) => {
        this.props.downloadByToken(token);
      })
      .catch((err) => {
        console.error('error ', err);
      })
      .finally(() => {
        this.setState({
          comfortReportGeneratingInProgress: false,
        });
      });
  }

  downloadUsersReport(type) {
    if (this.state.usersReportGeneratingInProgress) {
      return false;
    }

    this.setState({
      usersReportGeneratingInProgress: true,
    });

    return this.props.generateAnalyticsUsersReport(type, this.state.usersReportForm)
      .then((response) => {
        if (response === true) {
          return new Promise((resolve, reject) => {
            this.interval = setInterval(() => {
              const dates = this.state.usersReportForm;
              this.props.checkAnalyticsUsersReportReady(dates)
                .then((response) => {
                  if (response) {
                    clearInterval(this.interval);

                    resolve(response);
                  }
                });
            }, checkReportIntervalTime);

            setTimeout(() => {
              clearInterval(this.interval);

              // eslint-disable-next-line prefer-promise-reject-errors
              reject('max time exceeded');
            }, checkReportMaxTime);
          });
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Report generation error');
      })
      .then((token) => {
        this.props.downloadByToken(token);
      })
      .catch((err) => {
        console.error('error ', err);
      })
      .finally(() => {
        this.setState({
          usersReportGeneratingInProgress: false,
        });
      });
  }

  render() {
    return (
      <ReportDownload
        analyticsLists={this.props.analyticsLists}
        regionsList={[
          { value: null, label: 'Все' },
          ...this.props.regionsList.items.map((i) => ({
            value: i.id,
            label: i.name,
          })),
        ]}
        districtList={[
          { value: null, label: 'Все' },
          ...this.props.districtList.items.map((i) => ({
            value: i.id,
            label: i.name,
          })),
        ]}
        regionsFetching={this.props.regionsList.isFetching}
        districtFetching={this.props.districtList.isFetching || this.props.selectedDistrict.isFetching}
        handleChangeDateFilter={this.handleChangeDateFilter}
        handleUpdateListValue={this.handleUpdateListValue}
        downloadDetailedReport={this.downloadDetailedReport}
        handleChangeDateFilterFull={this.handleChangeDateFilterFull}
        handleChangeDateFilterComfort={this.handleChangeDateFilterComfort}
        handleChangeDateFilterTerms={this.handleChangeDateFilterTerms}
        handleChangeDateFilterUsers={this.handleChangeDateFilterUsers}
        downloadIssuesFullReport={this.downloadIssuesFullReport}
        downloadIssuesComfortReport={this.downloadIssuesComfortReport}
        downloadIssuesTermsReport={this.downloadIssuesTermsReport}
        downloadIssuesNotSolvedReport={this.downloadIssuesNotSolvedReport}
        downloadUsersReport={this.downloadUsersReport}
        handleDistrictChange={this.handleDistrictChange}
        handleRegionChange={this.handleRegionChange}
        state={this.state}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    statistic: {
      downloadSection: {
        analyticsLists,
        regionsList,
        districtList,
        selectedDistrict,
      },
    },
  } = state;

  return {
    analyticsLists,
    regionsList,
    districtList,
    selectedDistrict,
  };
};

export default connect(mapStateToProps, {
  generateAnalyticsIssuesFullReport,
  generateAnalyticsIssuesComfortReport,
  generateAnalyticsUsersReport,
  checkAnalyticsIssuesFullReportReady,
  checkAnalyticsIssuesComfortReportReady,
  checkAnalyticsUsersReportReady,
  fetchListValuesForReport,
  generateDetailedReport,
  generateAnalyticsIssuesTermsReport,
  generateAnalyticsIssuesNotSolvedReport,
  downloadByToken,
  getDistrictsDownloadSection,
  getRegionsDownloadSection,
  getDistrictsByRegionDownloadSection,
})(ReportDownloadContainer);
