import React from 'react';
import cn from 'classnames';
import styles from './popularThemes.module.css';
import Select from '../../ui-kit/select/select.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function PopularThemes(props) {
  const {
    issuesCategories,
    districtsOptions,
    regionsOptions,
    dataLoading,
    handleDistrictChange,
    handleRegionChange,
    locations,
    regionsLoading,
    districtsLoading,
    fieldsLoading,
  } = props;

  return (
    <section
      className={cn(styles.container, {
        'loading-container': dataLoading,
      })}
    >
      <h2 className={styles.title}>
        Популярные темы обращений
      </h2>
      <div className={styles.filters}>
        <Select
          placeholder="Федеральный округ"
          className={styles.filter}
          options={districtsOptions}
          disabled={fieldsLoading || districtsLoading}
          onChange={(district) => handleDistrictChange(district)}
          value={locations.district}
        />
        <Select
          placeholder="Регион"
          className={styles.filter}
          options={regionsOptions}
          disabled={fieldsLoading || regionsLoading}
          onChange={(region) => handleRegionChange(region)}
          value={locations.region}
        />
      </div>
      <div className={styles.tables}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td className={styles.tdNumber} />
              <td className={cn(styles.theadTd, styles.theadTdRegion)}>Тема</td>
              <td className={styles.theadTd}>Кол-во обращений</td>
              <td className={styles.theadTd}>Кол-во с ответом</td>
              <td className={styles.theadTd}>Кол-во решенных</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {issuesCategories.map((theme, index) => (
              <tr className={styles.tr} key={theme.id}>
                <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                <td className={cn(styles.td, styles.tdRegion)}>{theme.name}</td>
                <td className={styles.td}>{theme.statistic.total}</td>
                <td className={styles.td}>{theme.statistic.WithAnswerPrivate}</td>
                <td className={styles.td}>{theme.statistic.ResolvedPrivate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CssLoader />
    </section>
  );
}

export default PopularThemes;
