import axios from '../utils/axios';

export const getStatisticIssuesCategoriesRequest = 'GET_STATICTIC_ISSUES_CATEGORIES_REQUEST';
export const getStatisticIssuesCategoriesSuccess = 'GET_STATICTIC_ISSUES_CATEGORIES_SUCCESS';
export const getStatisticIssuesCategoriesFailure = 'GET_STATICTIC_ISSUES_CATEGORIES_FAILURE';

export const statisticIssuesCategoriesRequest = () => ({
  type: getStatisticIssuesCategoriesRequest,
});

export const statisticIssuesCategoriesSuccess = (items) => ({
  type: getStatisticIssuesCategoriesSuccess,
  payload: {
    items,
  },
});

export const statisticIssuesCategoriesFailure = (error) => ({
  type: getStatisticIssuesCategoriesFailure,
  payload: {
    error,
  },
});

export const getStatisticIssuesCategories = (filters) => (dispatch) => {
  dispatch(statisticIssuesCategoriesRequest());

  return axios('/api/statistic/issues/categories', {
    params: filters,
  })
    .then((response) => {
      dispatch(statisticIssuesCategoriesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(statisticIssuesCategoriesFailure(err));
    });
};
